import React, { useEffect, useState } from "react";
import "./myPage.css";
import { Button, Switch } from "@mui/material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CommonDialog from "../commonDialog/commonDialog";
import TextField from "@mui/material/TextField";
import { UpdateModel } from "../../../util/web_api";

function MyPage(props) {

  const [isProfileEditDlgOpen, setIsProfileEditDlgOpen] = useState(false);
  const [isOpenPhotGalleryEditDlg, setIsOpenPhotGalleryEditDlg] = useState(false);
  const [isOpenAccountSettingDlg, setIsOpenAccountSettingDlg] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(props.modelData.profilePhoto);
  const [firstName, setFirstName] = useState(props.modelData.firstName);
  const [secondName, setSecondName] = useState(props.modelData.secondName);
  const [selfPR, setSelfPR] = useState(props.modelData.selfPR);
  const sex = props.modelData.sex ? "女" : "男";
  const [isSendMail, setIsSendMail] = useState(props.modelData.isSendMail);

  // プロフィール編集ダイアログ用のステート
  const [previewProfilePhoto, setPreviewProfilePhoto] = useState("");
  const [previewFirstName, setPreviewFirstName] = useState("");
  const [previewSecondName, setPreviewSecondName] = useState("");
  const [previewSelfPR, setPreviewSelfPR] = useState("");
  const [previewSendMail, setPreviewSendMail] = useState(false);

  const onClickEditButton = async () => {
    setPreviewProfilePhoto(profilePhoto);
    setPreviewFirstName(firstName);
    setPreviewSecondName(secondName);
    setPreviewSelfPR(selfPR);
    setIsProfileEditDlgOpen(true);
  }

  const onClickAccountSettingButton = async () => {
    setPreviewSendMail(isSendMail);
  }

  useEffect(() => {
    if (isProfileEditDlgOpen) {
      onClickEditButton();
      console.log("aaa");
    }
  }, [isProfileEditDlgOpen])

  useEffect(() => {
    if (isOpenAccountSettingDlg) {
      onClickAccountSettingButton();
    }
  }, [isOpenAccountSettingDlg])

  console.log("render");

  const handleChoiceImage = (e) => {
    const files = e.target.files
    console.log(files);
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader()
      reader.onload = (e) => {
        setPreviewProfilePhoto(e.target.result)
      };
      reader.readAsDataURL(file)
    } else {
      console.log("0以下");
      setPreviewProfilePhoto("")
    }
  }

  const handleChangePreviewFirstName = (e) => {
    setPreviewFirstName(e.target.value);
  }

  const handleChangePreviewSecondName = (e) => {
    setPreviewSecondName(e.target.value);
  }

  const handleChangePreviewSelfPR = (e) => {
    setPreviewSelfPR(e.target.value);
  }

  const saveAccountSetting = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      const loginData = JSON.parse(token);
      console.log(loginData);
      console.log(previewSendMail);
      UpdateModel(loginData.mailaddress, previewSendMail, () => {
        setIsSendMail(previewSendMail);
        setIsOpenAccountSettingDlg(false);
      }, () => {
        setIsOpenAccountSettingDlg(false);
      })
    }
  }

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      author: '@bkristastucchio',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
      author: '@rollelflex_graphy726',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
      author: '@helloimnik',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      author: '@nolanissac',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      author: '@hjrc33',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
      author: '@tjdragotta',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
      author: '@katie_wasserman',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      author: '@silverdalex',
    },
  ];

  return (
    <div className="spMainMenuMyPage">
      <CommonDialog
        msg={
          <div>
            <div>プロフィール編集</div>
            <img src={previewProfilePhoto} alt={"画像表示に失敗しました"} style={{ width: "12vw", height: "12vw" }} />
            <input style={{ display: "block" }} type="file" accept="image/*" onChange={(e) => handleChoiceImage(e)} />
            <TextField
              onChange={handleChangePreviewFirstName}
              style={{ width: "15vw" }}
              label="姓"
              value={previewFirstName}
              variant="filled"
            />
            <TextField
              onChange={handleChangePreviewSecondName}
              style={{ width: "15vw" }}
              label="名"
              value={previewSecondName}
              variant="filled"
            />
            <TextField
              onChange={handleChangePreviewSelfPR}
              style={{ width: "25vw" }}
              label="自己PR"
              value={previewSelfPR}
              variant="filled"
              multiline
              required
              rows={5}
            />
          </div>
        }
        isOpen={isProfileEditDlgOpen}
        doYes={() => { console.log("111") }}
        doNo={() => { setIsProfileEditDlgOpen(false) }}
      />
      <CommonDialog
        msg={
          <div>
            <div>PhotoGallery編集</div>
            <ImageList style={{ padding: "10px" }} cols={3} sx={{ width: "25vw", height: "50vh" }}>
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <TextField
                    label="Title"
                    value={item.title}
                    variant="filled"
                  />
                  <TextField
                    label="SubTitle"
                    value={`by: ${item.author}`}
                    variant="filled"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        }
        isOpen={isOpenPhotGalleryEditDlg}
        doYes={() => { console.log("111") }}
        doNo={() => { setIsOpenPhotGalleryEditDlg(false) }}
      />
      <CommonDialog
        msg={
          <div>
            <div>アカウント設定</div>
            <div style={{ marginTop: "10px" }}>メール通知<Switch checked={previewSendMail} onChange={() => setPreviewSendMail(!previewSendMail)} /></div>
          </div>
        }
        isOpen={isOpenAccountSettingDlg}
        doYes={saveAccountSetting}
        doNo={() => { setIsOpenAccountSettingDlg(false) }}
      />
      <div className="spViewModelInstruction">
        <div>Instraction</div>
        <div className="spViewMe">
          <div className="spViewMyPhoto"><img src={profilePhoto} alt={"画像表示に失敗しました"} style={{ width: "30vw", height: "30vw" }} /></div>
          <div className="spViewMyDetail">
            <span className="spViewMyDetailColumn">名前</span><span>{firstName} {secondName}</span><br />
            <span className="spViewMyDetailColumn">性別</span><span>{sex}</span><br />
            <span className="spViewMyDetailColumn">自己紹介</span><span>{selfPR}</span>
          </div>
        </div>
        <Button
          variant="outlined"
          size='small'
          style={{ display: "block", marginLeft: "72vw", marginTop: "100px" }}
          onClick={onClickEditButton}>編集する</Button>
      </div>
      <div className="spViewModelPhotoGallery">
        <div>Photo Gallery</div>
        <ImageList cols={3} sx={{ width: "90vw", height: "100vh" }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.author}</span>}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Button
          variant="outlined"
          size="small"
          style={{ display: "block", marginLeft: "72vw", marginTop: "10px" }}
          onClick={() => setIsOpenPhotGalleryEditDlg(true)}
        >編集する</Button>
      </div>
      <div className="spViewModelOtherSetting">
        <div>Other Setting</div>
        <Button onClick={() => setIsOpenAccountSettingDlg(true)}>アカウント設定</Button>
      </div>
    </div>
  );
}

export { MyPage };
