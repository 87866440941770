import React, { useEffect, useState } from "react";
import "./profile.css";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

function Profile(props) {

  const [profilePhoto, setProfilePhoto] = useState(props.modelData.profilePhoto);
  const [firstName, setFirstName] = useState(props.modelData.firstName);
  const [secondName, setSecondName] = useState(props.modelData.secondName);
  const [selfPR, setSelfPR] = useState(props.modelData.selfPR);
  const sex = props.modelData.sex ? "女" : "男";

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      author: '@bkristastucchio',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
      author: '@rollelflex_graphy726',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
      author: '@helloimnik',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      author: '@nolanissac',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      author: '@hjrc33',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
      author: '@tjdragotta',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
      author: '@katie_wasserman',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      author: '@silverdalex',
    },
  ];

  return (
    <div className="pcMainMenuMyPage">
      <div className="pcViewModelInstruction">
        <div>Instraction</div>
        <div className="ViewMe">
          <div className="ViewMyPhoto"><img src={profilePhoto} alt={"画像表示に失敗しました"} style={{ width: "12vw", height: "12vw" }} /></div>
          <div className="ViewMyDetail">
            <span className="ViewMyDetailColumn">名前</span><span>{firstName} {secondName}</span><br />
            <span className="ViewMyDetailColumn">性別</span><span>{sex}</span><br />
            <span className="ViewMyDetailColumn">自己紹介</span><span>{selfPR}</span>
          </div>
        </div>
      </div>
      <div className="pcViewModelPhotoGallery">
        <div>Photo Gallery</div>
        <ImageList cols={3} sx={{ width: "50vw", height: "100vh" }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.author}</span>}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
}

export { Profile };
