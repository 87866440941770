import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, FormControl, InputLabel, FilledInput, Button } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "./bLogin.css";
import { useNavigate } from "react-router-dom";

function Biginer() {

    const navigation = useNavigate();

    return (
        <div className="bigLogin">
            <div className="bigHeadline">初めての方</div>
            <div className="bigLoginButton"><button style={{cursor:"pointer", color:"white", backgroundColor:"black", width:"30vw", height:"50px"}}
                onClick={() => navigation("/HairStylist_Regist")}>美容師 会員登録</button></div> 
            <div className="bigLoginButton"><button style={{cursor:"pointer", color:"white", backgroundColor:"black", width:"30vw", height:"50px"}}
                onClick={() => navigation("/Company_Regist")}>法人 会員登録</button></div> 
            <div className="bigLoginButton"><button style={{cursor:"pointer", color:"white", backgroundColor:"black", width:"30vw", height:"50px"}}
                onClick={() => navigation("/Model_Regist")}>モデル 応募</button></div> 
        </div>
    );
}

export {Biginer};
