import React from "react";
import { HeaderTop } from "../../control/header/header";
import { Footer } from "../../control/footer/footer";
import "./sPrivacy.css";

function SP_PrivacyPolicy() {


  return (
    <div>
      <HeaderTop />
      <div className="spsentence">
        <h1>プライバシーポリシー</h1>
        <div>
            株式会社Coupe（以下「当社」といいます。）は、当社の提供するCoupe（以下「本サービス」といいます。）
            における利用者情報の取扱いについて、以下のとおりサービス・プライバシーポリシー（以下「本ポリシー」といいます。）
            を定め、公表しております。本サービスをに登録する前に本ポリシーをご確認いただき、内容をご理解の上で本サービスをご利用下さい。<br/>
            なお、本ポリシーにおいて、「利用者情報」とは、お客様の識別に係る情報、通信サービス上の行動履歴、
            その他お客様のスマートフォンにおいてお客様と結びついた形で生成、利用又は蓄積されている情報であって、本ポリシーに基づき当社が取得するものを意味するものとします。<br/>
            また、利用者情報のうち、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）第2条第1項に定義される個人情報に該当するもの（以下「個人情報」といいます。）
            については、本ポリシーに定めるほか、当社が別途定める個人情報に関する下記のプライバシーポリシーが適用されますので、あわせてご参照下さい。
        </div>
        <h2>1. 当社の名称等</h2>
        <div>
            名称: 株式会社Coupe<br/>
            なお連絡先につきましては、info@coupe-tokyo.comにご連絡ください。
        </div>
        <h2>2. 取得する利用者情報</h2>
        <div>
            本ポリシーにおける「利用者情報」とは、ユーザーの識別にかかる情報、通信サービス上の行動履歴、そのユーザーの端末においてユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものとします。<br/>
            本サービスにおいて当社が収集する利用者情報は、その収集方法において、以下のようなものとなります。<br/>
            ・ユーザーからご提供いただく、氏名、メールアドレス、その他当社が定める入力フォームにユーザーが入力する情報。<br/>
            ・ユーザーが本サービスを利用するにあたって、他サービスと連携を許可することにより、当該他サービスからご提供いただく情報。<br/>
            ・ユーザーが本サービスを利用するにあたって、当社が本サービスへのアクセス状況やその他ご利用方法から収集する情報。これには端末情報、ログ情報、Cookieが含まれます。<br/>
            ・本サービスがトラフィックデータ収集のために利用しているGoogleアナリティクスから収集するユーザー属性情報（年齢、性別、興味や関心など）。なお、これらのデータは個人を特定できない形式に加工された上で収集されます。Googleアナリティクスの詳細についてはGoogle アナリティクス サービス利用規約をご確認ください。
        </div>
        <h2>3. 利用目的</h2>
        <div>
            ユーザーから取得した利用者情報は、以下の目的のために利用されます。<br/>
            ・本サービスに関する登録の受付、本人確認のため<br/>
            ・本サービスの提供、維持、保護及び改善のため<br/>
            ・本サービスに関するご案内、お問い合わせ等への対応のため<br/>
            ・本サービスに関する当社の規約、ポリシー等（以下「規約等」）に違反する行為への対応のため<br/>
            ・本サービスに関する規約等の変更などを通知するため<br/>
            ・当社のサービスに関連して、個人を特定できない形式に加工した統計データを作成するため<br/>
            ・当社または第三者の広告配信または表示のため<br/>
            ・その他マーケティングに利用するため<br/>
            ・上記の利用目的に付随する利用目的のため
        </div>
        <h2>4. 個人情報の訂正削除</h2>
        <div>
            当社はユーザーにより、個人情報の訂正、削除、利用停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、
            その結果に基づき、個人情報の内容の訂正、削除、利用停止を行い、その旨をユーザーに通知します。
            なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知します。
        </div>
        <h2>5. 免責</h2>
        <div>
            以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。<br/>
            ・ユーザー自らが当サービス上の機能または別の手段を用いて第三者に個人情報を明らかにする場合<br/>
            ・ユーザーが本サービス上に入力した情報、活動情報等により、期せずして個人が特定できてしまった場合<br/>
            ・第三者が当サイト外で個人情報その他の利用者情報を取得した場合<br/>
            ・ユーザーご本人以外がユーザー個人を識別できる情報（ID・パスワード等）を入手した場合
        </div>
        <h2>6. プライバシーポリシーの変更手続き</h2>
        <div>
            当社は利用者情報の取り扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。
            本ポリシーを変更する際は当ページにて公表させていただきます。
        </div>
        <h2>7. お問い合わせ</h2>
        <div>
            本ポリシーならびに利用者情報に関するお問い合わせは問い合わせフォームよりお願いいたします。
        </div>
    </div>
      <Footer />
    </div>
  );
}

export { SP_PrivacyPolicy };
