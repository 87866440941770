import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import RedeemIcon from '@mui/icons-material/Redeem';
import "./cTop.css";

function Characteristic() {

  const navigation = useNavigate("/test");

  return (
    <div className="spcharacteristic">
        <div className="spcHeadLine">サイトの特徴</div>
        <div className="spc">
          <div className="spcircleBorder"><LaptopChromebookIcon sx={{backgroundColor:"black", color:"rgb(255, 251, 241)"}} fontSize="small"/></div>
          <div className="spch">WEB上で簡単ハント</div>
          <div className="spcc">最短5分で完結！<br/>素敵なサロンモデルさんをたくさん集めておきました。<br/>サイト上でメッセージを送ることが可能です。</div>
        </div>
        <div className="spc">
          <div className="spcircleBorder"><AutoFixHighIcon sx={{backgroundColor:"black", color:"rgb(255, 251, 241)"}} fontSize="small"/></div>
          <div className="spch">厳選されたサロンモデル</div>
          <div className="spcc">書類審査、カメラテスト、面接を行い<br/>厳しい審査を通ったモデルさんのみ掲載しております。</div>
        </div>
        <div className="spc">
          <div className="spcircleBorder"><RedeemIcon sx={{backgroundColor:"black", color:"rgb(255, 251, 241)"}} fontSize="small"/></div>
          <div className="spch">サービス利用料無料</div>
          <div className="spcc">会員費、利用料金も完全無料。<br/>モデルさんへの謝礼のみで撮影依頼が出来ます。</div>
        </div>
    </div>
  );
}

export {Characteristic};
