import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

function CommonOkDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={props.doNo}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.msg}</DialogContent>
        <DialogActions>
          <Button onClick={props.doYes} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default CommonOkDialog;
