import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./filSearchModel.css";

function Filtered(){
    return(
        <div className='filterAria'>
            <Accordion className='filterAcordion'>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>条件で絞る</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        条件項目が入ります
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export {Filtered}