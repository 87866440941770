import React, { useEffect, useState } from "react";
import "./myPage.css";
import Image1 from "../../../Images/Rank1.jpg"
import { SideMenu } from "../sideMenu/sideMenu";
import { Button, TextField, Switch, Typography } from "@mui/material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CommonDialog from "../commonDialog/commonDialog";
import { UpdateHairdresser } from "../../../util/web_api";

function MyPage(props) {

  const [isProfileEditDlgOpen, setIsProfileEditDlgOpen] = useState(false);
  const [isOpenPhotGalleryEditDlg, setIsOpenPhotGalleryEditDlg] = useState(false);
  const [isOpenAccountSettingDlg, setIsOpenAccountSettingDlg] = useState(false);
  const [firstName, setFirstName] = useState(props.hairdresserData.firstName);
  const [secondName, setSecondName] = useState(props.hairdresserData.secondName);
  const [saloneAddress1, setSaloneAddress1] = useState(props.hairdresserData.saloneAddress1);
  const [saloneAddress2, setSaloneAddress2] = useState(props.hairdresserData.saloneAddress2);
  const [saloneAddress3, setSaloneAddress3] = useState(props.hairdresserData.saloneAddress3);
  const [isSendMail, setIsSendMail] = useState(props.hairdresserData.isSendMail);
  const [profilePhoto, setProfilePhoto] = useState(props.hairdresserData.profilePhoto);

  // プロフィール編集ダイアログ用のステート
  const [previewFirstName, setPreviewFirstName] = useState("");
  const [previewSecondName, setPreviewSecondName] = useState("");
  const [previewSaloneAddress1, setPreviewSaloneAddress1] = useState("");
  const [previewSaloneAddress2, setPreviewSaloneAddress2] = useState("");
  const [previewSaloneAddress3, setPreviewSaloneAddress3] = useState("");
  const [previewSendMail, setPreviewSendMail] = useState(false);

  const onClickEditButton = async () => {
    setPreviewFirstName(firstName);
    setPreviewSecondName(secondName);
    setPreviewSaloneAddress1(saloneAddress1);
    setPreviewSaloneAddress2(saloneAddress2);
    setPreviewSaloneAddress3(saloneAddress3);
    setIsProfileEditDlgOpen(true);
  }

  const onClickAccountSettingButton = async () => {
    setPreviewSendMail(isSendMail);
  }

  useEffect(() => {
    if (isProfileEditDlgOpen) {
      onClickEditButton();
      console.log("aaa");
    }
  }, [isProfileEditDlgOpen])

  useEffect(() => {
    if (isOpenAccountSettingDlg) {
      onClickAccountSettingButton();
    }
  }, [isOpenAccountSettingDlg])

  console.log("render");

  const handleChoiceImage = (e) => {
    const files = e.target.files
    console.log(files);
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader()
      reader.onload = (e) => {
        //setPreviewProfilePhoto(e.target.result)
      };
      reader.readAsDataURL(file)
    } else {
      console.log("0以下");
      //setPreviewProfilePhoto("")
    }
  }

  const saveAccountSetting = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      const loginData = JSON.parse(token);
      console.log(loginData);
      console.log(previewSendMail);
      UpdateHairdresser(loginData.mailaddress, previewSendMail, () => {
        setIsSendMail(previewSendMail);
        setIsOpenAccountSettingDlg(false);
      }, () => {
        setIsOpenAccountSettingDlg(false);
      })
    }
  }

  const handleChangePreviewFirstName = (e) => {
    setPreviewFirstName(e.target.value);
  }

  const handleChangePreviewSecondName = (e) => {
    setPreviewSecondName(e.target.value);
  }

  const handleChangePreviewSaloneAddress1 = (e) => {
    setPreviewSaloneAddress1(e.target.value);
  }

  const handleChangePreviewSaloneAddress2 = (e) => {
    setPreviewSaloneAddress2(e.target.value);
  }

  const handleChangePreviewSaloneAddress3 = (e) => {
    setPreviewSaloneAddress3(e.target.value);
  }

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      author: '@bkristastucchio',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
      author: '@rollelflex_graphy726',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
      author: '@helloimnik',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      author: '@nolanissac',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      author: '@hjrc33',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
      author: '@tjdragotta',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
      author: '@katie_wasserman',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      author: '@silverdalex',
    },
  ];

  return (
    <div className="pcMainMenuMyPage">
      <CommonDialog
        msg={
          <div>
            <div>プロフィール編集</div>
            <TextField
              onChange={handleChangePreviewFirstName}
              style={{ width: "15vw" }}
              label="姓"
              value={previewFirstName}
              variant="filled"
            />
            <TextField
              onChange={handleChangePreviewSecondName}
              style={{ width: "15vw" }}
              label="名"
              value={previewSecondName}
              variant="filled"
            />
            <TextField
              onChange={handleChangePreviewSaloneAddress1}
              style={{ width: "15vw" }}
              label="サロン住所1"
              value={previewSaloneAddress1}
              variant="filled"
            />
            <TextField
              onChange={handleChangePreviewSaloneAddress2}
              style={{ width: "15vw" }}
              label="サロン住所2"
              value={previewSaloneAddress2}
              variant="filled"
            />
            <TextField
              onChange={handleChangePreviewSaloneAddress3}
              style={{ width: "15vw" }}
              label="サロン住所3"
              value={previewSaloneAddress3}
              variant="filled"
            />
          </div>
        }
        isOpen={isProfileEditDlgOpen}
        doYes={() => { console.log("111") }}
        doNo={() => { setIsProfileEditDlgOpen(false) }}
      />
      <CommonDialog
        msg={
          <div>
            <div>PhotoGallery編集</div>
            <ImageList style={{ padding: "10px" }} cols={3} sx={{ width: "25vw", height: "50vh" }}>
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <TextField
                    label="Title"
                    value={item.title}
                    variant="filled"
                  />
                  <TextField
                    label="SubTitle"
                    value={`by: ${item.author}`}
                    variant="filled"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        }
        isOpen={isOpenPhotGalleryEditDlg}
        doYes={() => { console.log("111") }}
        doNo={() => { setIsOpenPhotGalleryEditDlg(false) }}
      />
      <CommonDialog
        msg={
          <div>
            <div>アカウント設定</div>
            <div style={{ marginTop: "10px" }}>メール通知<Switch checked={previewSendMail} onChange={() => setPreviewSendMail(!previewSendMail)} /></div>
          </div>
        }
        isOpen={isOpenAccountSettingDlg}
        doYes={saveAccountSetting}
        doNo={() => { setIsOpenAccountSettingDlg(false) }}
      />
      <div className="pcViewHairDresserInstruction">
        <div className="ViewMe">
          <div className="ViewMyPhoto"><img src={profilePhoto} style={{ width: "12vw", height: "12vw" }} /></div>
          <div className="ViewMyDetail">
            <Typography>{"PROFILE"}</Typography>
            <Typography>{firstName} {secondName}</Typography>
            <Typography>{saloneAddress1} {saloneAddress2} {saloneAddress3}</Typography>
          </div>
        </div>
        <Button
          variant="outlined"
          style={{ display: "block", marginLeft: "43vw", marginTop: "10px" }}
          onClick={() => { setIsProfileEditDlgOpen(true) }}>編集する</Button>
      </div>
      <div className="pcViewHairDresserPhotoGallery">
        <div>Photo Gallery</div>
        <ImageList cols={3} sx={{ width: "50vw", height: "100vh" }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.author}</span>}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Button
          variant="outlined"
          style={{ display: "block", marginLeft: "43vw", marginTop: "10px" }}
          onClick={() => setIsOpenPhotGalleryEditDlg(true)}>編集する</Button>
      </div>
      <div className="pcViewHairDresserOtherSetting">
        <div>Other Setting</div>
        <Button onClick={() => setIsOpenAccountSettingDlg(true)}>アカウント設定</Button>
      </div>
    </div>
  );
}

export { MyPage };
