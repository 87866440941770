import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderTop } from '../../control/header/header';
import { Footer } from '../../control/footer/footer';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import {
  GetOffer,
  PostChatRoom,
  RegistHairdresser,
  UpdateOfferState,
} from '../../../util/web_api';
import { DisplayUserName } from '../../control/displayUserName/displayUserName';
import './offer.css';
import Image1 from '../../../Images/Rank1.jpg';
import { SideMenu } from '../../control/sideMenu/sideMenu';
import { OFFER_STATE, PAGE_KIND } from '../../../util/define';
import { useEffect } from 'react';

function Offer(props) {
  const navigation = useNavigate('');
  const location = useLocation();

  const [offer, setOffer] = useState();

  const displayUserName = () => {
    const token = localStorage.getItem('token');
    if (token != null) {
      const clientData = JSON.parse(token);
      return clientData.mailaddress;
    } else {
      return 'ゲスト';
    }
  };

  const getOffer = () => {
    GetOffer(
      location.state.offerId,
      (res) => {
        console.log(res.data);
        setOffer(res.data);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  const updateOfferState = (offerState) => {
    UpdateOfferState(
      location.state.offerId,
      offerState,
      (res) => {
        console.log(res.data);
        if (offerState == OFFER_STATE.OK) {
          const poster = {
            ChatRoomId: offer.offerId,
            ModelId: offer.clientId,
            ClientId: offer.requesterId,
          };
          PostChatRoom(
            poster,
            (res) => {
              console.log(res.data);
              navigation('/chatRoom');
            },
            (e) => {
              console.log(e);
            }
          );
        } else if (offerState == OFFER_STATE.NG) {
          navigation('/offerList');
        }
      },
      (e) => {
        console.log(e);
      }
    );
  };

  useEffect(() => {
    getOffer();
  }, []);

  return (
    <div>
      <HeaderTop />
      <DisplayUserName userName={displayUserName()} />
      <div className="pcViewOffer">
        {offer != null ? (
          <React.Fragment>
            <div className="pcViewOfferOwner">
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                依頼元
              </div>
              <div className="ViewMe">
                <div className="ViewMyPhoto">
                  <img
                    src={offer.profilePhoto}
                    style={{ width: '12vw', height: '12vw' }}
                  />
                </div>
                <div className="ViewMyDetail">
                  <span className="ViewMyDetailColumn">名前</span>
                  <span>{offer.requesterName}</span>
                </div>
              </div>
            </div>
            <div className="pcViewOfferUsage">
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                撮影用途
              </div>
              <div>{offer.shootingUsage}</div>
            </div>
            <div className="pcViewOfferTime">
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                撮影希望日時・場所
              </div>
              <div>{offer.shootingPlace}</div>
              <div>{offer.shootingAddress}</div>
              <div>
                {offer.shootingDate}から{offer.shootingTime}時間
              </div>
            </div>
            <div className="pcViewOfferReward">
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                謝礼内容
              </div>
              <div>{offer.reward}</div>
            </div>
            <div className="pcViewOfferFixture">
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                撮影準備
              </div>
              <div>当日の仕込み：{offer.withPreparation ? '有' : '無'}</div>
              <div>当日のメイクの用意：{offer.withMake ? '有' : '無'}</div>
              <div>当日の衣装の用意：{offer.withCostume ? '有' : '無'}</div>
            </div>
            <div className="pcViewOfferOthers">
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                その他撮影の詳細
              </div>
              <div>{offer.others}</div>
            </div>
          </React.Fragment>
        ) : (
          <div>オファーを読み込んでいます</div>
        )}
        <Button
          variant="contained"
          color="error"
          onClick={() => updateOfferState(OFFER_STATE.NG)}
        >
          オファーを却下する
        </Button>
        <Button
          variant="contained"
          onClick={() => updateOfferState(OFFER_STATE.OK)}
        >
          オファーを承諾する
        </Button>
      </div>
      <SideMenu pageKind={PAGE_KIND.MYPAGE} />
      <Footer />
    </div>
  );
}

export { Offer };
