import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { HeaderTop } from "../../control/header/header";
import { Footer } from "../../control/footer/footer";
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Box, InputAdornment, MenuItem } from "@mui/material";
import { ChangeIsRegistSalone, RegistHairdresser, RegistSalone, SendOffer } from "../../../util/web_api";
import { DisplayUserName } from "../../control/displayUserName/displayUserName";
import "./saloneRegist.css";
import Image1 from "../../../Images/Rank1.jpg"
import { SideMenu } from "../../control/sideMenu/sideMenu";
import { PAGE_KIND } from "../../../util/define";
import CommonDialog from "../../control/commonDialog/commonDialog";
import { CheckBox } from "@mui/icons-material";

function SaloneRegist(children) {
    const navigation = useNavigate("");
    const location = useLocation();

    const [saloneName, setSaloneName] = useState("");
    const [saloneStartTime, setSaloneStartTime] = useState("");
    const [saloneEndTime, setSaloneEndTime] = useState("");
    const [isCash, setIsCash] = useState("");
    const [isCreditCard, setIsCreditCard] = useState("");
    const [handingCreditCard, setHandingCreditCard] = useState("");
    const [isOtherPay, setIsOtherPay] = useState("");
    const [otherPay, setOtherPay] = useState("");
    const [isParking, setIsParking] = useState("");
    const [parkingMarks, setParkingMarks] = useState("");
    const [numberOfSeat, setNumberOfSeat] = useState(0);
    const [isSmorking, setIsSmorking] = useState("");
    const [isChild, setIsChild] = useState("");
    const [saloneNumber, setSaloneNumber] = useState("");
    const [others, setOthers] = useState("");
    const [sellingPoint, setSellingPoint] = useState("");
    const [specialTreatment, setSpecialTreatment] = useState("");
    const [specialLength, setSpecialLength] = useState("");
    const [specialImage, setSpecialImage] = useState("");
    const [aboutTreatment, setAboutTreatment] = useState("");
    const [boom, setBoom] = useState("");
    const [position, setPosition] = useState("");
    const [image, setImage] = useState("");
    const [isOpenCheckDlg, setIsOpenCheckDlg] = useState(false);
    const [isMenuRegist, setIsMenuRegist] = useState(false);


    const handleChangeSaloneName = (e) => {
        setSaloneName(e.target.value);
    }

    const handleChangeSaloneStartTime = (e) => {
        setSaloneStartTime(e.target.value);
    }

    const handleChangeSaloneEndTime = (e) => {
        setSaloneEndTime(e.target.value);
    }

    const handleChangeIsCash = (e) => {
        setIsCash(e.target.value);
    }

    const handleChangeIsCreditCard = (e) => {
        setIsCreditCard(e.target.value);
    }

    const handleChangeHandingCreditCard = (e) => {
        setHandingCreditCard(e.target.value);
    }

    const handleChangeIsOtherPay = (e) => {
        setIsOtherPay(e.target.value);
    }

    const handleChangeOtherPay = (e) => {
        setOtherPay(e.target.value);
    }

    const handleChangeIsParking = (e) => {
        setIsParking(e.target.value);
    }

    const handleChangeParingMarks = (e) => {
        setParkingMarks(e.target.value);
    }

    const handleChangeNumberOfSeat = (e) => {
        setNumberOfSeat(e.target.value);
    }

    const handleChangeIsSmorking = (e) => {
        setIsSmorking(e.target.value);
    }

    const handleChangeIsChild = (e) => {
        setIsChild(e.target.value);
    }

    const handleChangeSaloneNumber = (e) => {
        setSaloneNumber(e.target.value);
    }

    const handleChangeOthers = (e) => {
        setOthers(e.target.value);
    }

    const handleChangeSellingPoint = (e) => {
        setSellingPoint(e.target.value);
    }

    const handleChangeSpecialTreatment = (e) => {
        setSpecialTreatment(e.target.value);
    }

    const handleChangeSpecialLength = (e) => {
        setSpecialLength(e.target.value);
    }

    const handleChangeSpecialImage = (e) => {
        setSpecialImage(e.target.value);
    }

    const handleChangeAboutTreatment = (e) => {
        setAboutTreatment(e.target.value);
    }

    const handleChangeBoom = (e) => {
        setBoom(e.target.value);
    }

    const handleChangePosition = (e) => {
        setPosition(e.target.value);
    }

    const handleChoiceImage = (e) => {
        const files = e.target.files
        console.log(files);
        if (files.length > 0) {
            var file = files[0];
            var reader = new FileReader()
            reader.onload = (e) => {
                setImage(e.target.result)
            };
            reader.readAsDataURL(file)
        } else {
            console.log("0以下");
            setImage("")
        }
    }

    const displayUserName = () => {
        const token = localStorage.getItem("token");
        if (token != null) {
            const clientData = JSON.parse(token);
            return clientData.mailaddress;
        } else {
            return 'ゲスト';
        }
    }

    const handlePostNewSalone = () => {
        const token = localStorage.getItem("token");
        const loginData = JSON.parse(token);
        const Poster = {
            Mailaddress: loginData.mailaddress,
            SaloneName: saloneName,
            SaloneImagePhoto: image,
            SaloneStartTime: new Date(saloneStartTime),
            SaloneEndTime: new Date(saloneEndTime),
            IsCash: isCash,
            IsCreditCard: isCreditCard,
            HandingCreditCard: handingCreditCard,
            IsOtherPay: isOtherPay,
            OtherPay: otherPay,
            IsParking: isParking,
            ParkingRemarks: parkingMarks,
            NumberOfSeat: numberOfSeat,
            IsSmorking: isSmorking,
            IsChild: isChild,
            SaloneNumber: saloneNumber,
            Others: others,
            SellingPoint: sellingPoint,
            SpecialTreatment: specialTreatment,
            SpecialLength: specialLength,
            SpecialImage: specialImage,
            AboutTreatment: aboutTreatment,
            Boom: boom,
            Position: position
        }

        console.log(Poster);
        RegistSalone(Poster, (res) => {
            console.log(res.data);
            ChangeIsRegistSalone(loginData.mailaddress, true, (r) => {
                console.log(r.data);
                if (isMenuRegist) {
                    navigation("/mySalone/menuRegist");
                } else {
                    navigation("/");
                }

            }, (er) => {
                console.warn(er);
            })
        }, (e) => {
            console.warn(e);
        })
    }

    return (
        <React.Fragment>
            <CommonDialog
                title="出店内容確認"
                msg={
                    <div>
                        <table>
                            <div><span style={{ backgroundColor: "black", width: "10px", display: "inline-block" }} >|</span>サロン情報</div>
                            <tr><th align="left">店名</th><td>{saloneName}</td></tr>
                            <tr><th align="left">営業開始時間</th><td>{saloneStartTime}</td></tr>
                            <tr><th align="left">営業終了時間</th><td>{saloneEndTime}</td></tr>
                            <tr><th align="left">現金支払い</th><td>{isCash}</td></tr>
                            <tr><th align="left">クレジットカード支払い</th><td>{isCreditCard}{handingCreditCard}</td></tr>
                            <tr><th align="left">その他決済</th><td>{isOtherPay}{otherPay}</td></tr>
                            <tr><th align="left">駐車場</th><td>{parkingMarks}</td></tr>
                            <tr><th align="left">席数</th><td>{numberOfSeat}</td></tr>
                            <tr><th align="left">店内喫煙</th><td>{isSmorking}</td></tr>
                            <tr><th align="left">お子様連れ</th><td>{isChild}</td></tr>
                            <div style={{ marginTop: "15px" }}><span style={{ backgroundColor: "black", width: "10px", display: "inline-block" }} >|</span>PR</div>
                            <tr><th align="left">アピールポイント</th><td>{sellingPoint}</td></tr>
                            <tr><th align="left">得意な施術</th><td>{specialTreatment} </td></tr>
                            <tr><th align="left">得意なレングス</th><td>{specialLength}</td></tr>
                            <tr><th align="left">得意なイメージ</th><td>{specialImage}</td></tr>
                            <tr><th align="left">施術について</th><td>{aboutTreatment}</td></tr>
                            <tr><th align="left">趣味・好きなこと</th><td>{boom}</td></tr>
                            <tr><th align="left">資格・役職</th><td>{position}</td></tr>
                        </table>
                        <FormGroup className="c_inputInfo" style={{ width: "300px" }}>
                            <FormControlLabel control={<Checkbox checked={isMenuRegist} onClick={() => setIsMenuRegist(!isMenuRegist)} />} label="続けて施術メニューの登録を行う" />
                        </FormGroup>
                    </div>
                }
                isOpen={isOpenCheckDlg}
                doYes={() => { handlePostNewSalone() }}
                doNo={() => { setIsOpenCheckDlg(false) }}
            />
            <HeaderTop />
            <DisplayUserName userName={displayUserName()} />
            <div className="pcViewOffer">
                <div className="mRegistSaloneTitle">出店登録</div>
                <div className="mRegistSaloneDetail">
                    出店登録しても、f.m.mユーザーにはサロンが表示されません<br />
                    サロンを表示させるには、出店登録した後に施術メニューの登録を行ってください
                </div>
                <div className="RegistSaloneForm">
                    <span className="m_registAttention">*は必須項目です</span>
                    <div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeSaloneName} style={{ width: "15vw" }} label="店名" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            住所：福岡県糟屋郡ゆりが丘6-3-23<br />
                            担当者名：末吉 勇希<br />
                            HP：https://aaaaaaaaaaaaaaaaaaa<br />
                            Instagram：instagram_id
                        </div>
                        <div className="hs_inputInfo">
                            <input type="file" accept="image/*" onChange={(e) => handleChoiceImage(e)} />
                            <div>サロンの外観を添付してください。</div>
                        </div>
                        <div className="m_inputInfo">
                            <TextField
                                onChange={handleChangeSaloneStartTime}
                                type="datetime-local"
                                label="営業時間(開始)"
                                variant="filled"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    width: "15vw"
                                }} />
                            <TextField
                                onChange={handleChangeSaloneEndTime}
                                type="datetime-local"
                                label="営業時間(開始)"
                                variant="filled"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    width: "15vw",
                                    marginLeft: "2vw"
                                }} />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeIsCash} style={{ width: "8vw" }} label="現金支払" variant="filled" required select>
                                <MenuItem value={true}>有</MenuItem>
                                <MenuItem value={false}>無</MenuItem>
                            </TextField>
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeIsCreditCard} style={{ width: "13vw" }} label="クレジットカード支払" variant="filled" required select>
                                <MenuItem value={true}>有</MenuItem>
                                <MenuItem value={false}>無</MenuItem>
                            </TextField>
                            <TextField onChange={handleChangeHandingCreditCard} style={{ width: "30vw", marginLeft: "2vw" }} label="取り扱いクレジットカード" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeIsOtherPay} style={{ width: "13vw" }} label="その他決済" variant="filled" required select>
                                <MenuItem value={true}>有</MenuItem>
                                <MenuItem value={false}>無</MenuItem>
                            </TextField>
                            <TextField onChange={handleChangeOtherPay} style={{ width: "30vw", marginLeft: "2vw" }} label="取り扱い決済手段" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeIsParking} style={{ width: "13vw" }} label="駐車場" variant="filled" required select>
                                <MenuItem value={true}>有</MenuItem>
                                <MenuItem value={false}>無</MenuItem>
                            </TextField>
                            <TextField onChange={handleChangeParingMarks} style={{ width: "30vw", marginLeft: "2vw" }} label="駐車場備考" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeNumberOfSeat} style={{ width: "8vw" }} label="席数" variant="filled" required />
                            <TextField onChange={handleChangeIsSmorking} style={{ width: "13vw", marginLeft: "2vw" }} label="店内喫煙" variant="filled" required select>
                                <MenuItem value={true}>可</MenuItem>
                                <MenuItem value={false}>不可</MenuItem>
                            </TextField>
                            <TextField onChange={handleChangeIsChild} style={{ width: "13vw", marginLeft: "2vw" }} label="お子様連れ" variant="filled" required select>
                                <MenuItem value={true}>可</MenuItem>
                                <MenuItem value={false}>不可</MenuItem>
                            </TextField>
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeSaloneNumber} style={{ width: "15vw" }} label="サロン電話番号" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeOthers} style={{ width: "45vw" }} label="その他" variant="filled" required />
                        </div>
                        <div className="FormTitle">サロンPR<span className="m_attention">*は必須項目です</span></div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeSellingPoint} style={{ width: "45vw" }} label="アピールポイント" variant="filled" required multiline rows={10} />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeSpecialTreatment} style={{ width: "45vw" }} label="得意な施術" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeSpecialLength} style={{ width: "45vw" }} label="得意なレングス" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeSpecialImage} style={{ width: "45vw" }} label="得意なイメージ" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeAboutTreatment} style={{ width: "45vw" }} label="施術について" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangeBoom} style={{ width: "45vw" }} label="趣味・好きなこと" variant="filled" required />
                        </div>
                        <div className="m_inputInfo">
                            <TextField onChange={handleChangePosition} style={{ width: "45vw" }} label="資格・役職" variant="filled" required />
                        </div>

                        <Button onClick={() => setIsOpenCheckDlg(true)} variant="contained" size="large">登録確認</Button>
                        <Button onClick={() => navigation("/")} variant="outlined" size="large">出店を取り消す</Button>
                    </div>
                </div>
            </div>
            <SideMenu pageKind={PAGE_KIND.MYPAGE} />
            <Footer />
        </React.Fragment>
    );
}

export { SaloneRegist };
