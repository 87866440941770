import React from "react";
import { HeaderTop } from "../../control/header/header";
import { Banner } from "../../control/top/banner/bTop";
import { OverView } from "../../control/top/overView/oTop";
import { Photo1 } from "../../control/top/photos/photo1";
import { Photo2 } from "../../control/top/photos/photo2";
import { Models } from "../../control/top/models/mTop";
import { Footer } from "../../control/footer/footer";
import { Characteristic } from "../../control/top/characteristic/cTop";
import { HowToUse } from "../../control/top/howToUse/htuTop";

function Top() {

  return (
    <div>
      <HeaderTop />
      <Banner />
      <OverView />
      <Photo1 />
      <Characteristic />
      <HowToUse />
      <Photo2 />
      <Models />
      {/* <UsageFee/>
        <UsagePay/> */}
      <Footer />
    </div>
  );
}

export { Top };
