import { atom, selector } from 'recoil';

export const watchingUserInfo = atom({
  key: 'watchingUserInfo',
  default: [
    {
      userKind: 9,
      mailaddress: ""
    },
  ],
});

export const watchingUserMailaddress = selector({
  key: 'watchingUserMailaddress',
  get: ({ get }) => {
    return get(watchingUserInfo).mailaddress;
  },
});

export const loading = atom({
  key: 'isLoading',
  default: false
})

export const waitApiCount = atom({
  key: 'waitApiCount',
  default: 0
})

export const incWaitApiCount = selector({
  key: 'incApiCount',
  get: ({get}) => get(waitApiCount),
  set: ({set}, v) => set(waitApiCount, waitApiCount + v)
})

export const decWaitApiCount = selector({
  key: 'decApiCount',
  get: ({get}) => get(waitApiCount),
  set: ({set}, v) => set(waitApiCount, waitApiCount - v)
})