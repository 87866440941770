import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FilledInput,
  Button,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './clLogin.css';
import { useNavigate } from 'react-router-dom';
import { CompanyLogin as DoCompanyLogin } from '../../../../util/web_api';
import { ErrorToast, SuccessToast } from '../../../../util/util';

function CompanyLogin() {
  const navigation = useNavigate();

  const [mailaddress, setMailaddress] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetail, setLoginDetail] = useState('');
  const [mailaddressInputColor, setMailaddressInputColor] = useState('primary');
  const [passwordInputColor, setPasswordInputColor] = useState('primary');

  const handleChangeMailaddress = (e) => {
    setMailaddress(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handleLoginModel = () => {
    if (mailaddress != '' && password != '') {
      DoCompanyLogin(
        mailaddress,
        password,
        (res) => {
          console.log(res.data);
          const token = {
            userKind: res.data.userKind,
            mailaddress: res.data.mailaddress,
            password: res.data.password,
          };
          localStorage.setItem('token', JSON.stringify(token));
          SuccessToast('ログインに成功しました');
          navigation('/hairdresser', { state: { resdata: res.data } });
        },
        (e) => {
          console.warn(e.response.data);
          ErrorToast('ログインに失敗しました');
          setLoginDetail(e.response.data);
          setMailaddressInputColor('primary');
          setPasswordInputColor('primary');
        }
      );
    } else {
      mailaddress == ''
        ? console.log('メールアドレスを入力してください')
        : console.log('パスワードを入力してください');
      setLoginDetail(
        mailaddress == ''
          ? 'メールアドレスを入力してください'
          : 'パスワードを入力してください'
      );
      setMailaddressInputColor(mailaddress == '' ? 'error' : 'primary');
      setPasswordInputColor(password == '' ? 'error' : 'primary');
    }
  };

  return (
    <div className="clLogin">
      <div className="clHeadline">美容師・法人ログイン</div>
      <div className="clMailAddress">
        <TextField
          color={mailaddressInputColor}
          style={{ width: '30vw' }}
          label="メールアドレス"
          value={mailaddress}
          variant="filled"
          onChange={handleChangeMailaddress}
        />
      </div>

      <div className="clPassword">
        <FormControl
          color={passwordInputColor}
          sx={{ width: '30vw' }}
          variant="filled"
        >
          <InputLabel>パスワード</InputLabel>
          <FilledInput
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>

      <div className="clLoginButton">
        <button
          style={{
            cursor: 'pointer',
            color: 'white',
            backgroundColor: 'black',
            width: '30vw',
            height: '50px',
          }}
          onClick={handleLoginModel}
        >
          ログイン
        </button>
      </div>
    </div>
  );
}

export { CompanyLogin };
