import React, { useEffect, useState } from 'react';
import './menuEdit.css';
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { RegistMenu } from '../../../util/web_api';
import CommonOkDialog from '../commonDialog/commonOkDialog';

const kinds = [
  { id: 0, name: 'カット' },
  { id: 1, name: 'カラー' },
  { id: 2, name: 'トリートメント' },
  { id: 3, name: 'パーマ' },
];

function MenuEdit(props) {
  const [menuName, setMenuName] = useState('');
  const [target, setTarget] = useState(0);
  const [kind, setKind] = useState([]);
  const [isValidityPeriod, setIsValidityPeriod] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [duration, setDuration] = useState(0);
  const [personId, setPersonId] = useState([]);
  const [others, setOthers] = useState('');
  const [isOpenRegistDlg, setIsOpenRegistDlg] = useState(false);

  const handleChangeMenuName = (e) => {
    setMenuName(e.target.value);
  };

  const handleChangeTarget = (e) => {
    setTarget(e.target.value);
  };

  const handleChangeKind = (event) => {
    const target = event.target.value;
    setKind(target);
  };

  const handleChangeIsValidityPeriod = (e) => {
    setIsValidityPeriod(e.target.value);
  };

  const handleChangeStart = (e) => {
    setStart(e.target.value);
  };

  const handleChangeEnd = (e) => {
    setEnd(e.target.value);
  };

  const handleChangeDuration = (e) => {
    setDuration(e.target.value);
  };

  const handleChangePerson = (event) => {
    const target = event.target.value;
    setPersonId(target);
  };

  const handleChangeOthers = (event) => {
    setOthers(target);
  };

  const handlePostNewMenu = () => {
    const Poster = {
      SaloneId: props.saloneId,
      MenuName: menuName,
      Target: target,
      IsValidityPeriod: isValidityPeriod,
      StartDay: start,
      EndDay: end,
      Duration: duration,
      Others: others,
      MenuKinds: kind,
      Hairdressers: personId,
    };

    console.log(Poster);
    RegistMenu(
      Poster,
      (res) => {
        console.log(res.data);
        setIsOpenRegistDlg(true);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <React.Fragment>
      <CommonOkDialog
        title={'メニューを登録しました'}
        msg={
          <div>
            <table>
              <tr>
                <th align="left">メニュー名</th>
                <td>{menuName}</td>
              </tr>
              <tr>
                <th align="left">対象者</th>
                <td>{target}</td>
              </tr>
              <tr>
                <th align="left">有効期限</th>
                <td>{isValidityPeriod ? '有' : '無'}</td>
              </tr>
              <tr>
                <th align="left">開始日</th>
                <td>{start}</td>
              </tr>
              <tr>
                <th align="left">終了日</th>
                <td>{end}</td>
              </tr>
              <tr>
                <th align="left">所要時間</th>
                <td>{duration}分</td>
              </tr>
              <tr>
                <th align="left">その他制限</th>
                <td>{others}</td>
              </tr>
            </table>
          </div>
        }
        isOpen={isOpenRegistDlg}
        doYes={() => {
          setIsOpenRegistDlg(false);
          setMenuName('');
          setTarget('');
          setIsValidityPeriod('');
          setStart('');
          setEnd('');
          setDuration('');
          setOthers('');
        }}
      />
      <div className="m_inputInfo">
        <TextField
          style={{ width: '100%' }}
          label="メニュー名"
          variant="filled"
          required
          multiline
          onChange={handleChangeMenuName}
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '20%' }}
          label="対象者"
          variant="filled"
          required
          select
          onChange={handleChangeTarget}
        >
          <MenuItem value={0}>新規</MenuItem>
          <MenuItem value={1}>再来</MenuItem>
          <MenuItem value={2}>全員</MenuItem>
        </TextField>
        <FormControl
          style={{ marginLeft: '20px', width: '50%' }}
          variant="filled"
        >
          <InputLabel>メニュー分類</InputLabel>
          <Select
            multiple
            value={kind}
            onChange={handleChangeKind}
            renderValue={(selected) => (
              <Box>
                {selected.map((value) => {
                  const menu = kinds.filter((kind) => kind.id === value);
                  if (menu.length > 0) {
                    return <Chip key={value} label={menu[0].name} />;
                  } else {
                    return;
                  }
                })}
              </Box>
            )}
          >
            {kinds.map((menu) => (
              <MenuItem key={menu.id} value={menu.id}>
                {menu.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '20%' }}
          label="有効期間"
          variant="filled"
          required
          select
          onChange={handleChangeIsValidityPeriod}
        >
          <MenuItem value={true}>有</MenuItem>
          <MenuItem value={false}>無</MenuItem>
        </TextField>
        <TextField
          type="date"
          label="開始日"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            width: '15vw',
            marginLeft: '20px',
          }}
          onChange={handleChangeStart}
        />
        <TextField
          type="date"
          label="終了日"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            width: '15vw',
            marginLeft: '10px',
          }}
          onChange={handleChangeEnd}
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '30%' }}
          label="所要時間"
          variant="filled"
          required
          select
          onChange={handleChangeDuration}
        >
          <MenuItem value={30}>30分</MenuItem>
          <MenuItem value={60}>1時間</MenuItem>
          <MenuItem value={90}>1時間30分</MenuItem>
          <MenuItem value={120}>2時間</MenuItem>
        </TextField>
      </div>
      <div className="m_inputInfo">
        <FormControl style={{ width: '100%' }} variant="filled">
          <InputLabel>対象スタイリスト</InputLabel>
          <Select
            multiple
            required
            value={personId}
            onChange={handleChangePerson}
            renderValue={(selected) => (
              <Box>
                {selected.map((value) => {
                  const info = props.stylistsInfo.filter(
                    (stylistInfo) => stylistInfo.userId === value
                  );
                  if (info.length > 0) {
                    return (
                      <Chip
                        key={value}
                        label={info[0].lastName + ' ' + info[0].firstName}
                      />
                    );
                  } else {
                    return;
                  }
                })}
              </Box>
            )}
          >
            {props.stylistsInfo.map((stylist) => (
              <MenuItem key={stylist.userId} value={stylist.userId}>
                {stylist.lastName + ' ' + stylist.firstName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '100%' }}
          label="その他条件"
          variant="filled"
          onChange={handleChangeOthers}
        />
      </div>
      <Button variant="contained" size="large" onClick={handlePostNewMenu}>
        登録する
      </Button>
      <Button variant="outlined" size="large">
        入力を取り消す
      </Button>
    </React.Fragment>
  );
}

export { MenuEdit };
