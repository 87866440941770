import React from "react";
import "./bLogin.css";
import { useNavigate } from "react-router-dom";

function Biginer() {

    const navigation = useNavigate();

    return (
        <div className="spbigLogin">
            <div className="spbigHeadline">初めての方</div>
            <div className="spbigLoginButton"><button style={{cursor:"pointer", color:"white", backgroundColor:"black", width:"70vw", height:"50px"}}
                onClick={() => navigation("/HairStylist_Regist")}>美容師 会員登録</button></div> 
            <div className="spbigLoginButton"><button style={{cursor:"pointer", color:"white", backgroundColor:"black", width:"70vw", height:"50px"}}
                onClick={() => navigation("/Company_Regist")}>法人 会員登録</button></div> 
            <div className="spbigLoginButton"><button style={{cursor:"pointer", color:"white", backgroundColor:"black", width:"70vw", height:"50px"}}
                onClick={() => navigation("/Model_Regist")}>モデル 応募</button></div> 
        </div>
    );
}

export {Biginer};
