import React, { useEffect } from 'react';
import axios from 'axios';
import { BASE_ADDRESS, USER_KIND } from './define';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  decWaitApiCount,
  incWaitApiCount,
  loading,
  waitApiCount,
} from '../recoil/atom';

// api実行に関するファイル
// 成功、失敗時の処理はコールバック関数としてsuccess, failureに記述

// 自動ログイン
export const AutoLogin = (user, mailaddress, password, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Users/Get${user}/?Mailaddress=${mailaddress}&Password=${password}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// モデルログイン
export const ModelLogin = (mailaddress, password, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Users/GetModel/?Mailaddress=${mailaddress}&Password=${password}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 会社ログイン
export const CompanyLogin = (mailaddress, password, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Users/GetHairdresser/?Mailaddress=${mailaddress}&Password=${password}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したメールアドレスのモデル取得
export const GetModelByMailaddress = (mailaddress, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Users/GetModelByMailaddress/?Mailaddress=${mailaddress}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// モデルを登録
export const RegistModel = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Users/Model`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 美容師を登録
export const RegistHairdresser = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Users/Hairdresser`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// サロンを登録
export const RegistSalone = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Salones/HairdresserRegistSalone`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// サロンを取得
export const GetSalone = (mailaddress, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Salones/GetSalone/?Mailaddress=${mailaddress}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 出店登録フラグを変更
export const ChangeIsRegistSalone = (
  mailaddress,
  isRegistSalone,
  success,
  failure
) => {
  axios
    .put(
      `${BASE_ADDRESS}Users/HairdresserRegistSaloneUpdate/?Mailaddress=${mailaddress}&IsRegistSalone=${isRegistSalone}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// サロンをすべて取得
export const GetAllSalone = (success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Salones/AllSalone`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// スタイリストを登録
export const RegistStylist = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Salones/RegistStylist`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// スタイリストを更新
export const UpdateStylist = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}Salones/updateStylist`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// メニューを登録
export const RegistMenu = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Salones/RegistMenu`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// サロンを取得
export const GetSaloneMenu = (saloneId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Salones/SaloneMenu/?SaloneId=${saloneId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したサロンIDのスタイリストをすべて取得
export const GetAllStylist = (saloneId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Salones/AllStylist/?SaloneId=${saloneId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 会社を登録
export const RegistCompany = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Users/Company`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// モデルをすべて取得
export const GetAllModel = (success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Users/AllModel`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したメールアドレスのモデルを更新
export const UpdateModel = (mailaddress, isSendMail, success, failure) => {
  axios
    .put(
      `${BASE_ADDRESS}Users/ModelUpdate/?Mailaddress=${mailaddress}&IsSendMail=${isSendMail}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したメールアドレスの美容師を更新
export const UpdateHairdresser = (
  mailaddress,
  isSendMail,
  success,
  failure
) => {
  axios
    .put(
      `${BASE_ADDRESS}Users/HairdresserUpdate/?Mailaddress=${mailaddress}&IsSendMail=${isSendMail}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// チャットルームを登録
export const PostChatRoom = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Chats/ChatRoom`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したユーザーのチャットルームを取得
export const GetChatRoomByMailAddress = (
  mailaddress,
  userKind,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}Chats/AllMyChatRoom/?Mailaddress=${mailaddress}&UserKind=${userKind}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したチャットルームIDのチャットルームを取得
export const GetChatRoomByChatRoomId = (chatRoomId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Chats/ChatRoom/?ChatRoomId=${chatRoomId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したチャットルームIDのメッセージをすべて取得
export const GetMessageByChatRoomId = (chatRoomId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Chats/Message/?ChatRoomId=${chatRoomId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// メッセージを送信します
export const SendMessage = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Chats/Message`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// オファーを送信します
export const SendOffer = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Offers/MakeOffer`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// モデルの受けたオファーを取得
export const GetModelsAllReceivedOffer = (mailaddress, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Offers/AllModelReceivedOffer/?Mailaddress=${mailaddress}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 美容師の出したオファーを取得
export const GetHairdressersAllRequestedOffer = (
  mailaddress,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}Offers/AllHairdresserRequestedOffer/?Mailaddress=${mailaddress}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したオファーを取得
export const GetOffer = (offerId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Offers/Offer/?id=${offerId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したオファー状況を更新
export const UpdateOfferState = (offerId, offerState, success, failure) => {
  axios
    .put(
      `${BASE_ADDRESS}Offers/UpdateOfferState/?OfferId=${offerId}&OfferState=${offerState}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// インターセプター
// requestで通信前共通処理
// responseで通信後共通処理
export const AxiosClientProvider = ({ children }) => {
  const setIsLoading = useSetRecoilState(loading);

  useEffect(() => {
    // リクエスト インターセプター
    const requestInterceptors = axios.interceptors.request.use(
      function (config) {
        setIsLoading(true);
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );

    // レスポンス インターセプター
    const responseInterceptor = axios.interceptors.response.use(
      function (responce) {
        setIsLoading(false);
        return responce;
      },
      function (err) {
        setIsLoading(false);
        return Promise.reject(err);
      }
    );

    // クリーンアップ
    return () => {
      axios.interceptors.request.eject(requestInterceptors);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return <React.Fragment>{children}</React.Fragment>;
};
