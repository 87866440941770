import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderTop } from '../../control/header/header';
import { Footer } from '../../control/footer/footer';
import './cRegist.css';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { RegistCompany } from '../../../util/web_api';

function SP_Company_Regist(children) {
  const navigation = useNavigate('');
  const location = useLocation();

  const [companyName, setCompanyName] = useState('');
  const [webURL, setWebURL] = useState('');
  const [managerFirstName, setManagerFirstName] = useState('');
  const [managerSecondName, setManagerSecondName] = useState('');
  const [managerKFirstName, setManagerKFirstName] = useState('');
  const [managerKSecondName, setManagerKSecondName] = useState('');
  const [mailaddress, setMailaddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [others, setOthers] = useState('');

  const handleChangeCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleChangeWebURL = (e) => {
    setWebURL(e.target.value);
  };

  const handleChangeManagerFirstName = (e) => {
    setManagerFirstName(e.target.value);
  };

  const handleChangeManagerSecondName = (e) => {
    setManagerSecondName(e.target.value);
  };

  const handleChangeManagerKFirstName = (e) => {
    setManagerKFirstName(e.target.value);
  };

  const handleChangeManagerKSecondName = (e) => {
    setManagerKSecondName(e.target.value);
  };

  const handleChangeMailaddress = (e) => {
    setMailaddress(e.target.value);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChangeOthers = (e) => {
    setOthers(e.target.value);
  };

  const handlePostNewCompany = () => {
    const Poster = {
      CompanyName: companyName,
      WebURL: webURL,
      ManagerFirstName: managerFirstName,
      ManagerKFirstName: managerKFirstName,
      ManagerSecondName: managerSecondName,
      ManagerKSecondName: managerKSecondName,
      Mailaddress: mailaddress,
      PhoneNumber: phoneNumber,
      Others: others,
    };

    console.log(Poster);
    RegistCompany(
      Poster,
      (res) => {
        console.log(res.data);
        navigation('/');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <div>
      <HeaderTop />
      <div className="spcRegistTitle">法人登録フォーム</div>
      <div className="spRegistForm">
        <div className="spFormTitle">
          登録フォーム<span className="spc_attention">*は必須項目です</span>
        </div>
        <div>
          <div className="spc_inputInfo">
            <TextField
              onChange={handleChangeCompanyName}
              style={{ width: '70vw' }}
              size="small"
              label="法人または店舗名"
              variant="filled"
              required
            />
          </div>
          <div className="spc_inputInfo">
            <TextField
              onChange={handleChangeWebURL}
              style={{ width: '70vw' }}
              size="small"
              label="webサイトURL"
              variant="filled"
              required
            />
          </div>
          <div className="spc_inputInfo">
            <TextField
              onChange={handleChangeManagerFirstName}
              style={{ width: '40vw' }}
              size="small"
              label="担当者(姓)"
              variant="filled"
              required
            />
            <TextField
              onChange={handleChangeManagerSecondName}
              style={{ width: '40vw', marginLeft: '2vw' }}
              size="small"
              label="担当者(名)"
              variant="filled"
              required
            />
          </div>
          <div className="spc_inputInfo">
            <TextField
              onChange={handleChangeManagerKFirstName}
              style={{ width: '40vw' }}
              size="small"
              label="担当者(セイ)"
              variant="filled"
              required
            />
            <TextField
              onChange={handleChangeManagerKSecondName}
              style={{ width: '40vw', marginLeft: '2vw' }}
              size="small"
              label="担当者(メイ)"
              variant="filled"
              required
            />
          </div>
          <div className="spc_inputInfo">
            <TextField
              onChange={handleChangeMailaddress}
              style={{ width: '60vw' }}
              size="small"
              label="メールアドレス"
              variant="filled"
              required
            />
          </div>
          <div className="spc_inputInfo">
            <TextField
              onChange={handleChangePhoneNumber}
              style={{ width: '60vw' }}
              size="small"
              label="ご連絡先"
              variant="filled"
              required
            />
          </div>
          <div className="spc_inputInfo" style={{ marginBottom: '40px' }}>
            <TextField
              onChange={handleChangeOthers}
              s
              style={{ width: '82vw' }}
              label="ご質問等"
              variant="filled"
              multiline
              rows={3}
            />
          </div>
          <Link
            underline="hover"
            target="_blank"
            style={{ cursor: 'pointer' }}
            to="/termsService"
          >
            利用規約
          </Link>
          <FormGroup className="c_inputInfo" style={{ width: '170px' }}>
            <FormControlLabel control={<Checkbox />} label="利用規約に同意" />
          </FormGroup>
          <Button
            onClick={handlePostNewCompany}
            variant="contained"
            size="large"
            style={{ marginTop: '10px' }}
          >
            登録する(まだ登録は完了しません)
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export { SP_Company_Regist };
