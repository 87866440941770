import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Footer } from "../../control/footer/footer";
import { HeaderTop } from "../../control/header/header";
import { DisplayRank } from "../../control/displayRank/displayRank";
import { DisplayUserName } from "../../control/displayUserName/displayUserName";
import { MyPage } from "../../control/modelMyPage/myPage";
import { SideMenu } from "../../control/sideMenu/sideMenu";
import { PAGE_KIND, USER_KIND } from "../../../util/define";
import { ChatRoom } from "../../control/chatRoom/chatRoom";
import { Box, Button, Divider, Tabs, Tab } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import { GetHairdressersAllRequestedOffer, GetModelsAllReceivedOffer } from "../../../util/web_api";
import { useState } from "react";
import { useEffect } from "react";
import './offerList.css'

function OfferList() {

    const navigation = useNavigate("");
    const location = useLocation();

    const token = localStorage.getItem("token");
    const clientData = JSON.parse(token);
    const [requestedOffers, setRequestedOffers] = useState([]);
    const [receivedOffers, setReceivedOffers] = useState([]);
    const [offerKind, setOfferKind] = useState(0);

    const handleChangeOfferKind = (e, v) => {
        setOfferKind(v);
    }

    const displayUserName = () => {
        const token = localStorage.getItem("token");
        if (token != null) {
            const clientData = JSON.parse(token);
            return clientData.mailaddress;
        } else {
            return 'ゲスト';
        }
    }

    const getReceivedOffers = () => {
        if (clientData.userKind == USER_KIND.MODEL) {
            GetModelsAllReceivedOffer(clientData.mailaddress, (res) => {
                console.log(res.data);
                setReceivedOffers(res.data);
            }, (e) => {
                console.log(e);
            })
        }
    }

    const getRequestedOffers = () => {
        if (clientData.userKind == USER_KIND.HAIRDRESSER) {
            GetHairdressersAllRequestedOffer(clientData.mailaddress, (res) => {
                console.log(res.data);
                setRequestedOffers(res.data);
            }, (e) => {
                console.log(e);
            })
        }
    }

    useEffect(() => {
        getRequestedOffers();
        getReceivedOffers();
    }, [])

    return (
        <div>
            <HeaderTop />
            <DisplayUserName userName={displayUserName()} />
            <DisplayRank />
            <div className="pcMainMenuOffer">
                <TabContext value={offerKind}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs textColor="inherit" TabIndicatorProps={{ style: { backgroundColor: "black" } }} value={offerKind} onChange={handleChangeOfferKind} centered>
                            <Tab style={{ width: "50%" }} label="受けたオファー一覧" />
                            <Tab style={{ width: "50%" }} label="出したオファー一覧" />
                        </Tabs>
                    </Box>
                    <TabPanel value={0}>
                        {receivedOffers.map((offer) => {
                            return (
                                <React.Fragment>
                                    <div className="pcViewOfferList" style={{ padding: "10px", marginBottom: "5px", marginTop: "5px" }} onClick={() => navigation("/offerDetail", { state: { offerId: offer.offerId } })}>
                                        <img src={offer.profilePhoto} alt="画像表示に失敗しました" style={{ width: "8vw", height: "8vw" }} />
                                        <div style={{ display: "inline-block", width: "70%", verticalAlign: "top" }}>
                                            <table>
                                                <tr><th align="left">美容師</th><td>{offer.requesterName}</td></tr>
                                                <tr><th align="left">撮影希望日時</th><td>{offer.shootingDate}から{offer.shootingTime}時間</td></tr>
                                                <tr><th align="left">撮影場所</th><td>{offer.shootingPlace}</td></tr>
                                                <tr><th align="left">謝礼内容</th><td>{offer.reward}</td></tr>
                                            </table>
                                        </div>
                                    </div>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </TabPanel>
                    <TabPanel value={1}>
                        {requestedOffers.map((offer) => {
                            return (
                                <React.Fragment>
                                    <div className="pcViewOfferList" style={{ padding: "10px", marginBottom: "5px", marginTop: "5px" }}>
                                        <img src={offer.profilePhoto} alt="画像表示に失敗しました" style={{ width: "8vw", height: "8vw" }} />
                                        <div style={{ display: "inline-block", width: "70%", verticalAlign: "top" }}>
                                            <table>
                                                <tr><th align="left">美容師</th><td>{offer.requesterName}</td></tr>
                                                <tr><th align="left">撮影希望日時</th><td>{offer.shootingDate}から{offer.shootingTime}時間</td></tr>
                                                <tr><th align="left">撮影場所</th><td>{offer.shootingPlace}</td></tr>
                                                <tr><th align="left">謝礼内容</th><td>{offer.reward}</td></tr>
                                            </table>
                                        </div>
                                    </div>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </TabPanel>
                </TabContext>

            </div>
            <SideMenu pageKind={PAGE_KIND.CHATROOM} />
            <Footer />
        </div>
    );
}

export { OfferList };
