import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, FormControl, InputLabel, FilledInput, Button } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "./mlLogin.css";
import { useNavigate } from "react-router-dom";
import { ModelLogin as DoModelLogin } from "../../../../util/web_api";

function ModelLogin() {

    const navigation = useNavigate();

    const [mailaddress, setMailaddress] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loginDetail, setLoginDetail] = useState("");
    const [mailaddressInputColor, setMailaddressInputColor] = useState("primary")
    const [passwordInputColor, setPasswordInputColor] = useState("primary")

    const handleChangeMailaddress = (e) => {
        setMailaddress(e.target.value);
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleClickShowPassword = () => {
        setShowPassword(showPassword ? false : true);
    }

    const handleLoginModel = () => {
        if (mailaddress != "" && password != "") {
            DoModelLogin(mailaddress, password, (res) => {
                console.log(res.data);
                const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                localStorage.setItem('token', JSON.stringify(token));
                navigation("/model", { state: { resdata: res.data, pageKind: 0 } });
            }, (e) => {
                console.warn(e.response.data);
                setLoginDetail(e.response.data);
                setMailaddressInputColor("primary");
                setPasswordInputColor("primary");
            });
        } else {
            mailaddress == "" ? console.log("メールアドレスを入力してください") : console.log("パスワードを入力してください");
            setLoginDetail(mailaddress == "" ? "メールアドレスを入力してください" : "パスワードを入力してください");
            setMailaddressInputColor(mailaddress == "" ? "error" : "primary");
            setPasswordInputColor(password == "" ? "error" : "primary");
        }
    }

    return (
        <div className="spmlLogin">
            <div className="spmlHeadline">モデルログイン</div>
            <div className="spmlMailAddress">
                <TextField style={{ width: "70vw" }}
                    color={mailaddressInputColor}
                    label="メールアドレス"
                    value={mailaddress}
                    variant="filled"
                    onChange={handleChangeMailaddress} />
            </div>
            <div className="spmlPassword">
                <FormControl color={passwordInputColor} sx={{ width: '70vw' }} variant="filled">
                    <InputLabel>パスワード</InputLabel>
                    <FilledInput
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handleChangePassword}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <div style={{ color: "red", marginTop: "10px" }}>{loginDetail}</div>
            <div className="spmlLoginButton">
                <button style={{ cursor: "pointer", color: "white", backgroundColor: "black", width: "70vw", height: "50px" }}
                    onClick={handleLoginModel}>
                    ログイン
                </button>
            </div>
        </div>
    );
}

export { ModelLogin };
