import React from "react";
import Image1 from "./../../../../Images/image2.jpg";
import Image2 from "./../../../../Images/image3.jpg";
import Image3 from "./../../../../Images/image4.jpg";
import Image4 from "./../../../../Images/image9.jpg";
import "./photoSize.css";

function Photo1() {
  return (
    <div className="sphoto_div">
        <div className="sphoto1_div">
            <img src={Image1} className="sphoto"/>
        </div>
        <div className="sphoto2_div">
            <img src={Image4} className="sphoto"/>
        </div>
        <div className="sphoto1_div">
            <img src={Image2} className="sphoto"/>
        </div>
        <div className="sphoto2_div">
            <img src={Image3} className="sphoto"/>
        </div>
    </div>
  );
}

export {Photo1};
