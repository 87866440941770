import React from "react";
import { HeaderTop } from "../../control/header/header";
import { Footer } from "../../control/footer/footer";
import { PAGE_KIND } from "../../../util/define";
import "./SalonePage.css";
import { SaloneList } from "../../control/saloneList/SaloneList";
import { SideMenu } from "../../control/sideMenu/sideMenu";
import { DisplayRank } from "../../control/displayRank/displayRank";
import { DisplayUserName } from "../../control/displayUserName/displayUserName";

function SalonePage() {

    // const [models, setModels] = useState([]);
    // const [watchingUser, setWatchingUser] = useRecoilState(watchingUserInfo);

    // const navigation = useNavigate("");

    // const handleOpenPage = async () => {
    //     GetAllModel((res) => {
    //         setModels(res.data);
    //         console.log(res.data);
    //     }, (e) => {
    //         console.warn(e);
    //     })
    // }

    // useEffect(() => {
    //     handleOpenPage();
    // }, [])


    const displayUserName = () => {
        const token = localStorage.getItem("token");
        if (token != null) {
          const clientData = JSON.parse(token);
          return clientData.mailaddress;
        } else {
          return 'ゲスト';
        }
      }

    return (
        <div>
            <HeaderTop />
            <DisplayUserName userName={displayUserName()} />
            <DisplayRank />
            <SaloneList />
            <SideMenu pageKind={PAGE_KIND.SALONE} />
            <Footer />
        </div>
    );
}

export { SalonePage };
