import React, { useEffect, useState } from 'react';
import './stylistEdit.css';
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { GetAllStylist, RegistStylist } from '../../../util/web_api';
import CommonOkDialog from '../commonDialog/commonOkDialog';

function StylistEdit(props) {
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [introduce, setIntroduce] = useState();
  const [fee, setFee] = useState();
  const [firstPR, setFirstPR] = useState();
  const [secondPR, setSecondPR] = useState();
  const [specialImage, setSpecialImage] = useState();
  const [specialTech, setSpecialTech] = useState();
  const [isOpenRegistDlg, setIsOpenRegistDlg] = useState(false);

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeIntroduce = (e) => {
    setIntroduce(e.target.value);
  };

  const handleChangeFee = (e) => {
    setFee(e.target.value);
  };

  const handleChangeFirstPR = (e) => {
    setFirstPR(e.target.value);
  };

  const handleChangeSecondPR = (e) => {
    setSecondPR(e.target.value);
  };

  const handleChangeSpecialImage = (e) => {
    setSpecialImage(e.target.value);
  };

  const handleChangeSpecialTech = (e) => {
    setSpecialTech(e.target.value);
  };

  const getStylist = () => {
    GetAllStylist(
      props.saloneId,
      (res) => {
        console.log(res.data);
        props.setStylists(res.data);
      },
      (e) => {
        console.log(e.response.data);
      }
    );
  };

  // 入力した情報を消去する
  const deleteStylistInfo = () => {
    setLastName('');
    setFirstName('');
    setIntroduce('');
    setFee('');
    setFirstPR('');
    setSecondPR('');
    setSpecialImage('');
    setSpecialTech('');
  };

  const handlePostNewStylst = () => {
    const Poster = {
      SaloneId: props.saloneId,
      LastName: lastName,
      FirstName: firstName,
      Introduce: introduce,
      Fee: fee,
      FirstPR: firstPR,
      SecondPR: secondPR,
      SpecialImage: specialImage,
      SpecialTech: specialTech,
    };

    console.log(Poster);
    RegistStylist(
      Poster,
      (res) => {
        console.log(res.data);
        setIsOpenRegistDlg(true);
        deleteStylistInfo();
        getStylist();
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <React.Fragment>
      <CommonOkDialog
        title={'スタイリストを登録しました'}
        msg={
          <div>
            <table>
              <tr>
                <th align="left">スタイリスト名</th>
                <td>
                  {lastName} {firstName}
                </td>
              </tr>
              <tr>
                <th align="left">自己紹介</th>
                <td>{introduce}</td>
              </tr>
              <tr>
                <th align="left">指名料</th>
                <td>{fee}円</td>
              </tr>
              <tr>
                <th align="left">自己PR</th>
                <td>{firstPR}</td>
              </tr>
              <tr>
                <th align="left"></th>
                <td>{secondPR}</td>
              </tr>
              <tr>
                <th align="left">得意なイメージ</th>
                <td>{specialImage}</td>
              </tr>
              <tr>
                <th align="left">得意な技術</th>
                <td>{specialTech}</td>
              </tr>
            </table>
          </div>
        }
        isOpen={isOpenRegistDlg}
        doYes={() => {
          setIsOpenRegistDlg(false);
        }}
      />
      <div className="m_inputInfo">
        <TextField
          style={{ width: '30%' }}
          value={lastName}
          onChange={handleChangeLastName}
          label="苗字"
          variant="filled"
          required
        />
        <TextField
          style={{ width: '30%', marginLeft: '20px' }}
          value={firstName}
          onChange={handleChangeFirstName}
          label="名前"
          variant="filled"
          required
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '50%' }}
          value={introduce}
          onChange={handleChangeIntroduce}
          label="自己紹介"
          variant="filled"
          required
        />
        <TextField
          style={{ width: '30%', marginLeft: '20px' }}
          value={fee}
          onChange={handleChangeFee}
          label="指名料"
          variant="filled"
          required
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '100%' }}
          value={firstPR}
          onChange={handleChangeFirstPR}
          label="自己PR(強調表示されます)"
          variant="filled"
          required
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '100%' }}
          value={secondPR}
          onChange={handleChangeSecondPR}
          label="自己PR"
          variant="filled"
          required
          multiline
          rows={3}
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '100%' }}
          value={specialImage}
          onChange={handleChangeSpecialImage}
          label="得意なイメージ"
          variant="filled"
          required
          multiline
        />
      </div>
      <div className="m_inputInfo">
        <TextField
          style={{ width: '100%' }}
          value={specialTech}
          onChange={handleChangeSpecialTech}
          label="得意な技術"
          variant="filled"
          required
          multiline
        />
      </div>
      <Button onClick={handlePostNewStylst} variant="contained" size="large">
        登録する
      </Button>
      <Button
        onClick={() => deleteStylistInfo()}
        variant="outlined"
        size="large"
      >
        入力を取り消す
      </Button>
    </React.Fragment>
  );
}

export { StylistEdit };
