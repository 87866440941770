import React from "react";
import { Link } from "@mui/material"
import "./footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigation = useNavigate();
  return (
    <div>
      <div className="footer">
        <div className="pcFooterSiteName">f.m.m</div>
        <div className="pcFooterColumnContainer">
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/Model_Regist")}>モデル 応募</Link></div>
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/HairStylist_Regist")}>美容師 新規登録</Link></div>
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/Company_Regist")}>企業の方はこちらから</Link></div>
        </div>
        <div className="pcFooterColumnContainer">
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/inquiry")}>よくあるご質問</Link></div>
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/inquiry")}>お問い合わせフォーム</Link></div>
        </div>
        <div className="pcFooterHeadLine">ログイン</div>
        <div className="pcFooterColumnContainer">
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/account/login")}>モデル</Link></div>
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/account/login")}>美容師・法人</Link></div>
        </div>
        <div className="pcFooterHeadLine">サービスご利用にあたって</div>
        <div className="pcFooterColumnContainer">
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/privacy")}>プライバシーポリシー</Link></div>
          <div className="pcFooterLinkContainer"><Link color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/termsService")}>利用規約</Link></div>
        </div>
        <div style={{width:"60vw", marginLeft:"20vw", paddingBottom: "35px", borderBottom:"1px solid black"}}></div>
      </div>
      <div className="bottomFooter">
        ©f.m.m All Rights Reserved.
      </div>
    </div>
  );
}

export {Footer};
