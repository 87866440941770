import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// 共通
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilValue } from 'recoil';
import { loading } from './recoil/atom';
import { Backdrop, CircularProgress } from '@mui/material';
import { AxiosClientProvider } from './util/web_api';

// PC画面
import { Top } from './viewPC/container/top/Top';
import { Login } from './viewPC/container/login/Login';
import { SearchModel } from './viewPC/container/searchUser/SearchModel';
import { PrivacyPolicy } from './viewPC/container/privacyPolicy/privacy';
import { TermsService } from './viewPC/container/termsService/TermsService';
import { HairDresserPage } from './viewPC/container/hairdresserPage/HairDresserPage';
import { ModelPage } from './viewPC/container/modelPage/ModelPage';
import { Model_Regist } from './viewPC/container/modelRegist/mRegist';
import { Company_Regist } from './viewPC/container/companyRegist/cRegist';
import { HairStylist_Regist } from './viewPC/container/hairdresserRegist/hsRegist';
import { Inquiry } from './viewPC/container/Inquiry/Inquiry';
import { ProfilePage } from './viewPC/container/profile/ModelPage';
import { DisplaySearchUser } from './viewPC/container/displaySearchUser/DisplaySearchUserPage';
import { ChatRoomPage } from './viewPC/container/chatRoom/chatRoom';
import { ChatPage } from './viewPC/container/chat/chat';

// スマホ画面
import { SP_Top } from './viewSmartPhone/container/top/Top';
import { SP_Login } from './viewSmartPhone/container/login/Login';
import { SP_SearchModel } from './viewSmartPhone/container/searchUser/SearchModel';
import { SP_PrivacyPolicy } from './viewSmartPhone/container/privacyPolicy/privacy';
import { SP_TermsService } from './viewSmartPhone/container/termsService/TermsService';
import { SP_HairDresserPage } from './viewSmartPhone/container/hairdresserPage/HairDresserPage';
import { SP_ModelPage } from './viewSmartPhone/container/modelPage/ModelPage';
import { SP_Model_Regist } from './viewSmartPhone/container/modelRegist/mRegist';
import { SP_Company_Regist } from './viewSmartPhone/container/companyRegist/cRegist';
import { SP_HairStylist_Regist } from './viewSmartPhone/container/hairdresserRegist/hsRegist';
import { SP_Inquiry } from './viewSmartPhone/container/Inquiry/Inquiry';
import { SP_ProfilePage } from './viewSmartPhone/container/profile/ModelPage';
import { SP_DisplaySearchUser } from './viewSmartPhone/container/displaySearchUser/DisplaySearchUserPage';
import { SP_ChatRoomPage } from './viewSmartPhone/container/chatRoom/chatRoom';
import { SP_ChatPage } from './viewSmartPhone/container/chat/chat';
import { Offer } from './viewPC/container/offer/offer';
import { MakeOffer } from './viewPC/container/makeOffer/makeOffer';
import { OfferList } from './viewPC/container/offerList/offerList';
import { SalonePage } from './viewPC/container/salonePage/SalonePage';
import { MySalone } from './viewPC/container/mySalone/mySalone';
import { SaloneRegist } from './viewPC/container/saloneRegist/saloneRegist';
import { MenuRegist } from './viewPC/container/menuRegist/menuRegist';
import { StaffRegist } from './viewPC/container/staffRegist/staffRegist';

function App() {
  const isLoading = useRecoilValue(loading);
  if (window.outerWidth > 1000) {
    // PC画面のUI
    return (
      <BrowserRouter>
        <AxiosClientProvider>
          <ToastContainer />
          {isLoading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : null}
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/account/login" element={<Login />} />
            <Route path="/search/user" element={<SearchModel />} />
            <Route path="/search/user/detail" element={<DisplaySearchUser />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/termsService" element={<TermsService />} />
            <Route path="/hairdresser" element={<HairDresserPage />} />
            <Route path="/model" element={<ModelPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/Model_Regist" element={<Model_Regist />} />
            <Route path="/Company_Regist" element={<Company_Regist />} />
            <Route
              path="/HairStylist_Regist"
              element={<HairStylist_Regist />}
            />
            <Route path="/inquiry" element={<Inquiry />} />
            <Route path="/offerDetail" element={<Offer />} />
            <Route path="/makeOffer" element={<MakeOffer />} />
            <Route path="/offerList" element={<OfferList />} />
            <Route path="/chatRoom" element={<ChatRoomPage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/salonePage" element={<SalonePage />} />
            <Route path="/MySalone" element={<MySalone />} />
            <Route path="/MySalone/SaloneRegist" element={<SaloneRegist />} />
            <Route path="/MySalone/MenuRegist" element={<MenuRegist />} />
            <Route path="/MySalone/StylistRegist" element={<StaffRegist />} />
          </Routes>
        </AxiosClientProvider>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <AxiosClientProvider>
          <ToastContainer />
          {isLoading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : null}
          <Routes>
            <Route path="/" element={<SP_Top />} />
            <Route path="/account/login" element={<SP_Login />} />
            <Route path="/search/user" element={<SP_SearchModel />} />
            <Route
              path="/search/user/detail"
              element={<SP_DisplaySearchUser />}
            />
            <Route path="/privacy" element={<SP_PrivacyPolicy />} />
            <Route path="/termsService" element={<SP_TermsService />} />
            <Route path="/hairdresser" element={<SP_HairDresserPage />} />
            <Route path="/model" element={<SP_ModelPage />} />
            <Route path="/profile" element={<SP_ProfilePage />} />
            <Route path="/Model_Regist" element={<SP_Model_Regist />} />
            <Route path="/Company_Regist" element={<SP_Company_Regist />} />
            <Route
              path="/HairStylist_Regist"
              element={<SP_HairStylist_Regist />}
            />
            <Route path="/inquiry" element={<SP_Inquiry />} />
            <Route path="/chatRoom" element={<SP_ChatRoomPage />} />
            <Route path="/chat" element={<SP_ChatPage />} />
          </Routes>
        </AxiosClientProvider>
      </BrowserRouter>
    );
  }
}

export default App;
