import React from "react";
import Image1 from "./../../../../Images/image2.jpg";
import Image2 from "./../../../../Images/image3.jpg";
import Image3 from "./../../../../Images/image4.jpg";
import Image4 from "./../../../../Images/image9.jpg";
import "./photoSize.css";

function Photo2() {
  return (
    <div className="photo_div_a">
        <div className="photo1_div">
            <img src={Image2} className="photo2"/>
            <img src={Image1} className="photo1"/>
        </div>
        <div className="photo2_div">
            <img src={Image4} className="photo1"/>
            <img src={Image3} className="photo2"/>
        </div>
        
    </div>
  );
}

export {Photo2};
