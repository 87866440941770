import React, { useEffect, useState } from "react";
import "./chatRoom.css";
import { Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetChatRoomByChatRoomId, GetChatRoomByMailAddress, GetUsersInfoByUserId } from "../../../util/web_api";
import { PAGE_KIND } from "../../../util/define";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loading, loadingSelector, reverseLoading } from "../../../recoil/atom";

function ChatRoom(props) {

  const token = localStorage.getItem("token");
  const clientData = JSON.parse(token);

  const navigation = useNavigate();
  const [chatRooms, setChatRooms] = useState([]);
  const [userProfile, setUserProfile] = useState([]);

  const getChatRoom = () => {
    GetChatRoomByMailAddress(clientData.mailaddress, clientData.userKind, (res) => {
      console.log(res.data);
      setChatRooms(res.data);
    }, (e) => {
      console.log(e);
    })
  }

  // const getUserProfile = () => {
  //   const userIds = distinctUserIds();
  //   const poster = {
  //     UserIds: userIds,
  //     UserKind: clientData.userKind
  //   }
  //   console.log(poster);
  //   GetUsersInfoByUserId(poster, (res) => {
  //     console.log(res.data);
  //   }, (e) => {
  //     console.log(e);
  //   })
  // }

  const handleClickChatRoom = (chatRoomId) => {
    GetChatRoomByChatRoomId(chatRoomId, (res) => {
      console.log(res.data);
      navigation('/chat', { state: { resdata: { mailaddress: clientData.mailaddress }, pageKind: PAGE_KIND.CHAT, chatRoomId: chatRoomId } });
    }, (e) => {
      console.log(e);
    })
  }

  useEffect(() => {
    getChatRoom()
  }, [])

  return (
    <div className="pcMainMenuChatRoom">
      {chatRooms.map((chatRoom) => {
        return (
          <React.Fragment>
            <img src={clientData.userKind == 0 ? chatRoom.hairdresserProfilePhoto : chatRoom.modelProfilePhoto} alt="画像表示に失敗しました" style={{ width: "10vw", height: "10vw", marginTop: "20px" }} />
            <Button onClick={() => handleClickChatRoom(chatRoom.chatRoomId)}>{`${clientData.userKind == 0 ? chatRoom.hairdresserName : chatRoom.modelName}さんとのチャット`}</Button>
            <Divider />
          </React.Fragment>
        )
      })}
    </div>
  );
}

export { ChatRoom };
