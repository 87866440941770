import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderTop } from '../../control/header/header';
import { Footer } from '../../control/footer/footer';
import { Box, Button, Divider, Tabs, Tab } from '@mui/material';
import {
  CompanyLogin,
  GetSalone,
  RegistHairdresser,
  SendOffer,
} from '../../../util/web_api';
import { TabPanel, TabContext } from '@mui/lab';
import { DisplayUserName } from '../../control/displayUserName/displayUserName';
import './menuRegist.css';
import Image1 from '../../../Images/Rank1.jpg';
import { SideMenu } from '../../control/sideMenu/sideMenu';
import { PAGE_KIND, USER_KIND } from '../../../util/define';
import CommonDialog from '../../control/commonDialog/commonDialog';
import { MenuEdit } from '../../control/menuEdit/menuEdit';

function MenuRegist(children) {
  const navigation = useNavigate('');
  const location = useLocation();
  const token = localStorage.getItem('token');
  const clientData = JSON.parse(token);

  const [isNewMenu, setIsNewMenu] = useState(0);

  const displayUserName = () => {
    if (token != null) {
      return clientData.mailaddress;
    } else {
      return 'ゲスト';
    }
  };

  const handleChange = (e, v) => {
    setIsNewMenu(v);
  };

  return (
    <React.Fragment>
      <HeaderTop />
      <DisplayUserName userName={displayUserName()} />
      <div className="pcViewOffer">
        <TabContext value={isNewMenu}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              textColor="inherit"
              TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
              value={isNewMenu}
              onChange={handleChange}
              centered
            >
              <Tab style={{ width: '50%' }} label="既存メニュー" />
              <Tab style={{ width: '50%' }} label="新規メニュー" />
            </Tabs>
          </Box>
          <TabPanel value={0}>
            <div className="mRegistMenuTitle">メニュー登録</div>
            <div className="mRegistMenuDetail">
              既存メニューの確認・変更を行います
            </div>
          </TabPanel>
          <TabPanel value={1}>
            <div className="mRegistMenuTitle">メニュー登録</div>
            <div className="mRegistMenuDetail">
              新規メニューの登録を行います
            </div>
            <MenuEdit
              saloneId={location.state.saloneId}
              stylistsInfo={location.state.stylistsInfo}
            />
          </TabPanel>
        </TabContext>
      </div>
      <SideMenu pageKind={PAGE_KIND.MYPAGE} />
      <Footer />
    </React.Fragment>
  );
}

export { MenuRegist };
