import React from "react";
import Image1 from "./../../../../Images/image5.jpg";
import Image2 from "./../../../../Images/image6.jpg";
import "./photoSize.css";

function Photo2() {
  return (
    <div>
        <img src={Image1} style={{width:"90vw", marginLeft:"5vw", marginTop:"100px"}}/>
    </div>
  );
}

export {Photo2};
