import React, { useEffect, useState } from 'react';
import './stylistChange.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  GetAllStylist,
  RegistStylist,
  UpdateStylist,
} from '../../../util/web_api';
import CommonOkDialog from '../commonDialog/commonOkDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function StylistChange(props) {
  const [lastName, setLastName] = useState(props.stylistInfo.lastName);
  const [firstName, setFirstName] = useState(props.stylistInfo.firstName);
  const [introduce, setIntroduce] = useState(props.stylistInfo.introduce);
  const [fee, setFee] = useState(props.stylistInfo.fee);
  const [firstPR, setFirstPR] = useState(props.stylistInfo.firstPR);
  const [secondPR, setSecondPR] = useState(props.stylistInfo.secondPR);
  const [specialImage, setSpecialImage] = useState(
    props.stylistInfo.specialImage
  );
  const [specialTech, setSpecialTech] = useState(props.stylistInfo.specialTech);
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenRegistDlg, setIsOpenRegistDlg] = useState(false);

  // 一時保存用
  const [tempLastName, setTempLastName] = useState(props.stylistInfo.lastName);
  const [tempFirstName, setTempFirstName] = useState(
    props.stylistInfo.firstName
  );
  const [tempIntroduce, setTempIntroduce] = useState(
    props.stylistInfo.introduce
  );
  const [tempFee, setTempFee] = useState(props.stylistInfo.fee);
  const [tempFirstPR, setTempFirstPR] = useState(props.stylistInfo.firstPR);
  const [tempSecondPR, setTempSecondPR] = useState(props.stylistInfo.secondPR);
  const [tempSpecialImage, setTempSpecialImage] = useState(
    props.stylistInfo.specialImage
  );
  const [tempSpecialTech, setTempSpecialTech] = useState(
    props.stylistInfo.specialTech
  );

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeIntroduce = (e) => {
    setIntroduce(e.target.value);
  };

  const handleChangeFee = (e) => {
    setFee(e.target.value);
  };

  const handleChangeFirstPR = (e) => {
    setFirstPR(e.target.value);
  };

  const handleChangeSecondPR = (e) => {
    setSecondPR(e.target.value);
  };

  const handleChangeSpecialImage = (e) => {
    setSpecialImage(e.target.value);
  };

  const handleChangeSpecialTech = (e) => {
    setSpecialTech(e.target.value);
  };

  // 更新したときのstate更新
  const didUpdate = () => {
    setTempLastName(lastName);
    setTempFirstName(firstName);
    setTempIntroduce(introduce);
    setTempFee(fee);
    setTempFirstPR(firstPR);
    setTempSecondPR(secondPR);
    setTempSpecialImage(specialImage);
    setTempSpecialTech(specialTech);
  };

  // 更新を取り消したときのstate更新
  const canceledUpdate = () => {
    setLastName(tempLastName);
    setFirstName(tempFirstName);
    setIntroduce(tempIntroduce);
    setFee(tempFee);
    setFirstPR(tempFirstPR);
    setSecondPR(tempSecondPR);
    setSpecialImage(tempSpecialImage);
    setSpecialTech(tempSpecialTech);
  };

  const handleUpdateStylist = () => {
    const Poster = {
      UserId: props.stylistInfo.userId,
      LastName: lastName,
      FirstName: firstName,
      Introduce: introduce,
      Fee: fee,
      FirstPR: firstPR,
      SecondPR: secondPR,
      SpecialImage: specialImage,
      SpecialTech: specialTech,
    };

    console.log(Poster);
    UpdateStylist(
      Poster,
      (res) => {
        console.log(res.data);
        didUpdate();
        setIsOpenRegistDlg(true);
        setIsEdit(false);
        props.setIsEditMode(false);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <React.Fragment>
      <CommonOkDialog
        title={'スタイリストの情報を変更しました'}
        msg={
          <div>
            <table>
              <tr>
                <th align="left">スタイリスト名</th>
                <td>
                  {lastName} {firstName}
                </td>
              </tr>
              <tr>
                <th align="left">自己紹介</th>
                <td>{introduce}</td>
              </tr>
              <tr>
                <th align="left">指名料</th>
                <td>{fee}円</td>
              </tr>
              <tr>
                <th align="left">自己PR</th>
                <td>{firstPR}</td>
              </tr>
              <tr>
                <th align="left"></th>
                <td>{secondPR}</td>
              </tr>
              <tr>
                <th align="left">得意なイメージ</th>
                <td>{specialImage}</td>
              </tr>
              <tr>
                <th align="left">得意な技術</th>
                <td>{specialTech}</td>
              </tr>
            </table>
          </div>
        }
        isOpen={isOpenRegistDlg}
        doYes={() => {
          setIsOpenRegistDlg(false);
        }}
      />
      <Accordion
        expanded={isOpenAccordion}
        onChange={() => setIsOpenAccordion(!isOpenAccordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {lastName} {firstName}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            指名料: {fee}円
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isEdit ? (
            <React.Fragment>
              <div className="m_inputInfo">
                <TextField
                  style={{ width: '30%' }}
                  value={lastName}
                  onChange={handleChangeLastName}
                  label="苗字"
                  variant="filled"
                  required
                />
                <TextField
                  style={{ width: '30%', marginLeft: '20px' }}
                  value={firstName}
                  onChange={handleChangeFirstName}
                  label="名前"
                  variant="filled"
                  required
                />
              </div>
              <div className="m_inputInfo">
                <TextField
                  style={{ width: '50%' }}
                  value={introduce}
                  onChange={handleChangeIntroduce}
                  label="自己紹介"
                  variant="filled"
                  required
                />
                <TextField
                  style={{ width: '30%', marginLeft: '20px' }}
                  value={fee}
                  onChange={handleChangeFee}
                  label="指名料"
                  variant="filled"
                  required
                />
              </div>
              <div className="m_inputInfo">
                <TextField
                  style={{ width: '100%' }}
                  value={firstPR}
                  onChange={handleChangeFirstPR}
                  label="自己PR(強調表示されます)"
                  variant="filled"
                  required
                />
              </div>
              <div className="m_inputInfo">
                <TextField
                  style={{ width: '100%' }}
                  value={secondPR}
                  onChange={handleChangeSecondPR}
                  label="自己PR"
                  variant="filled"
                  required
                  multiline
                  rows={3}
                />
              </div>
              <div className="m_inputInfo">
                <TextField
                  style={{ width: '100%' }}
                  value={specialImage}
                  onChange={handleChangeSpecialImage}
                  label="得意なイメージ"
                  variant="filled"
                  required
                  multiline
                />
              </div>
              <div className="m_inputInfo">
                <TextField
                  style={{ width: '100%' }}
                  value={specialTech}
                  onChange={handleChangeSpecialTech}
                  label="得意な技術"
                  variant="filled"
                  required
                  multiline
                />
              </div>
              <Button
                onClick={() => {
                  handleUpdateStylist();
                }}
                variant="contained"
                size="large"
              >
                登録する
              </Button>
              <Button
                onClick={() => {
                  canceledUpdate();
                  setIsEdit(false);
                  props.setIsEditMode(false);
                }}
                variant="outlined"
                size="large"
              >
                入力を取り消す
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <table>
                <tr>
                  <th align="left">スタイリスト名</th>
                  <td>
                    {lastName} {firstName}
                  </td>
                </tr>
                <tr>
                  <th align="left">自己紹介</th>
                  <td>{introduce}</td>
                </tr>
                <tr>
                  <th align="left">指名料</th>
                  <td>{fee}円</td>
                </tr>
                <tr>
                  <th align="left">自己PR</th>
                  <td>{firstPR}</td>
                </tr>
                <tr>
                  <th align="left"></th>
                  <td>{secondPR}</td>
                </tr>
                <tr>
                  <th align="left">得意なイメージ</th>
                  <td>{specialImage}</td>
                </tr>
                <tr>
                  <th align="left">得意な技術</th>
                  <td>{specialTech}</td>
                </tr>
              </table>
              <div style={{ textAlign: 'right' }}>
                <Button
                  style={{ marginLeft: 'auto', marginRight: '0px' }}
                  onClick={() => {
                    setIsEdit(true);
                    props.setIsEditMode(true);
                  }}
                  disabled={props.isEditMode}
                >
                  変更する
                </Button>
              </div>
            </React.Fragment>
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export { StylistChange };
