// 定義ファイル
// 定数を定義する
// ひとまとまりのものは連想配列にしてまとめること

export const BASE_ADDRESS = 'https://devfmm.ddo.jp/api/api/';
//export const BASE_ADDRESS = 'https://localhost:44363/api/';

export const USER_KIND = {
  MODEL: 0,
  HAIRDRESSER: 1,
  COMPANY: 2,
};

export const PAGE_KIND = {
  MYPAGE: 0,
  PROFILE: 1,
  CHATROOM: 2,
  CHAT: 3,
  SALONE: 4,
};

export const OFFER_STATE = {
  OFFER: 0,
  OK: 1,
  NG: 2,
};
