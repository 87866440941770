import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import banner from "./../../../../Images/image2.jpg";
import "./bTop.css";

function Banner() {

  const navigation = useNavigate("/test");

  return (
    <div className="tbanner">
        <div className="tbannerElements">
                <div className="tsiteName">f.m.m</div>
              <div className="theadLine">福岡のサロンモデルがすぐ見つかる！</div>
              {/* <div className="complement">個人集客をして売上を上げたい美容師さん<br/>福岡でサロンモデル、モデルとして輝きたい女性、男性<br/>f.m.mはそんな方々が今よりもっと活躍出来る場所として誕生したサイトです。</div> */}
        </div>
        <div className="tbannerDiv">
                <img src={banner} className="tbannerImage"/>
        </div>
    </div>
  );
}

export {Banner};
