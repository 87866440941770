import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Footer } from "../../control/footer/footer";
import { HeaderTop } from "../../control/header/header";
import { DisplayRank } from "../../control/displayRank/displayRank";
import { DisplayUserName } from "../../control/displayUserName/displayUserName";
import { SideMenu } from "../../control/sideMenu/sideMenu";
import { PAGE_KIND } from "../../../util/define";

function SP_ProfilePage() {

  const navigation = useNavigate("");
  const location = useLocation();

  const displayUserName = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      const clientData = JSON.parse(token);
      return clientData.mailaddress;
    } else {
      return 'ゲスト';
    }
  }

  return (
    <div>
      <HeaderTop />
      <DisplayUserName userName={displayUserName()} />
      <DisplayRank />
      <SP_ProfilePage modelData={location.state.resdata} />
      <SideMenu pageKind={PAGE_KIND.PROFILE} />
      <Footer />
    </div>
  );
}

export { SP_ProfilePage };
