import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import Picture from "./../../../../Images/pic.png";
import "./oTop.css";

function OverView() {

  const navigation = useNavigate("/test");

  return (
    <div className="spoverView">
      <div className="spaboutUst">このサイトについて</div>
      <img className="spaboutUsPic" src={Picture} height="200px" />
      <div className="spoHeadLine">福岡の美容師とモデルをつなぐWEBサービス</div>
      <div className="spoComplement">個人集客をして売上を上げたい美容師さん<br/>福岡でサロンモデル、モデルとして輝きたい女性、男性<br/>f.m.mはそんな方々が今よりもっと活躍出来る場所として誕生したサイトです。</div>
      <Button variant="outlined" color="inherit" sx={"color:black;"} style={{fontSize: "small", marginTop: 10, marginRight: 10}}
              onClick={() => navigation("/HairStylist_Regist")}>美容師新規登録</Button>
      <Button variant="outlined" color="inherit" sx={"color:black;"} style={{fontSize: "small", marginTop: 10, marginRight:10}}
              onClick={() => navigation("/Model_Regist")}>モデル応募</Button>
      <Button variant="outlined" color="inherit" sx={"color:black;"} style={{fontSize: "small", marginTop: 10}}
              onClick={() => navigation("/Company_Regist")}>企業の方</Button>  
    </div>
  );
}

export {OverView};
