import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderTop } from '../../control/header/header';
import { Footer } from '../../control/footer/footer';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Box,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import {
  CompanyLogin,
  GetAllStylist,
  GetSalone,
  RegistHairdresser,
  SendOffer,
} from '../../../util/web_api';
import { DisplayUserName } from '../../control/displayUserName/displayUserName';
import './mySalone.css';
import Image1 from '../../../Images/Rank1.jpg';
import { SideMenu } from '../../control/sideMenu/sideMenu';
import { PAGE_KIND, USER_KIND } from '../../../util/define';
import CommonDialog from '../../control/commonDialog/commonDialog';

function MySalone(children) {
  const navigation = useNavigate('');
  const location = useLocation();
  const token = localStorage.getItem('token');
  const clientData = JSON.parse(token);

  const [saloneData, setSaloneData] = useState();
  const [stylists, setStylists] = useState(null);

  const displayUserName = () => {
    if (token != null) {
      return clientData.mailaddress;
    } else {
      return 'ゲスト';
    }
  };

  useEffect(() => {
    GetSalone(
      clientData.mailaddress,
      (res) => {
        console.log(res.data);
        setSaloneData(res.data);
        GetAllStylist(
          res.data.saloneId,
          (res) => {
            console.log(res.data);
            setStylists(res.data);
          },
          (e) => {
            console.log(e.response.data);
          }
        );
      },
      (e) => {
        console.log(e.response.data);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <HeaderTop />
      <DisplayUserName userName={displayUserName()} />
      <div className="pcViewOffer">
        {saloneData ? (
          <React.Fragment>
            <div className="mMySaloneTitle">マイサロン</div>
            <div className="mMySaloneDetail">
              登録情報の確認・変更を行います
            </div>
            <div className="mSaloneinfo">
              <h3>サロン情報</h3>
              <div style={{ marginLeft: '30px' }}>
                <img
                  src={saloneData.saloneImagePhoto}
                  style={{ width: '20%' }}
                />
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <div>{saloneData.saloneName}</div>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <Button variant="outlined">サロン情報の確認・変更</Button>
                </div>
              </div>
            </div>
            <div className="mStylistinfo">
              <h3>スタイリスト</h3>
              <div style={{ marginLeft: '30px' }}>
                <div>
                  {stylists !== null
                    ? `${stylists.length}人のスタイリストが登録されています`
                    : 'スタイリストを登録してください。'}
                </div>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() =>
                      navigation('/mySalone/stylistRegist', {
                        state: {
                          saloneId: saloneData.saloneId,
                          stylistsInfo: stylists,
                        },
                      })
                    }
                    variant="outlined"
                  >
                    スタイリスト{stylists !== null ? 'の確認・変更' : '登録'}
                  </Button>
                </div>
              </div>
            </div>
            <div className="mMenuinfo">
              <h3>メニュー</h3>
              <div style={{ marginLeft: '30px' }}>
                <div>メニューが登録されていません。</div>
                <div>
                  {stylists === null &&
                    'スタイリストの登録を行い、メニューの登録を行ってください。'}
                </div>
                {stylists !== null ? (
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() =>
                        navigation('/mySalone/menuRegist', {
                          state: {
                            saloneId: saloneData.saloneId,
                            stylistsInfo: stylists,
                          },
                        })
                      }
                      variant="outlined"
                    >
                      メニュー登録
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              出店登録をすることで、f.m.m利用者があなたのサロンを予約してくれるようになります
              <br />
              出店登録から始めましょう
            </div>
            <Button
              onClick={() => {
                if (clientData.userKind == USER_KIND.HAIRDRESSER) {
                  CompanyLogin(
                    clientData.mailaddress,
                    clientData.password,
                    (res) => {
                      const token = {
                        userKind: res.data.userKind,
                        mailaddress: res.data.mailaddress,
                        password: res.data.password,
                      };
                      localStorage.setItem('token', JSON.stringify(token));
                      navigation('/MySalone/SaloneRegist');
                    },
                    (e) => {
                      navigation('/account/login');
                    }
                  );
                }
              }}
            >
              出店登録する
            </Button>
          </React.Fragment>
        )}
      </div>
      <SideMenu pageKind={PAGE_KIND.MYPAGE} />
      <Footer />
    </React.Fragment>
  );
}

export { MySalone };
