import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Footer } from "../../control/footer/footer";
import { HeaderTop } from "../../control/header/header";
import { DisplayRank } from "../../control/displayRank/displayRank";
import { DisplayUserName } from "../../control/displayUserName/displayUserName";
import { MyPage } from "../../control/modelMyPage/myPage";
import { SideMenu } from "../../control/sideMenu/sideMenu";
import { PAGE_KIND } from "../../../util/define";
import { ChatRoom } from "../../control/chatRoom/chatRoom";

function SP_ChatRoomPage() {

  const navigation = useNavigate("");
  const location = useLocation();

  const displayUserName = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      const clientData = JSON.parse(token);
      return clientData.mailaddress;
    } else {
      return 'ゲスト';
    }
  }

  return (
    <div>
      <HeaderTop />
      <SideMenu pageKind={PAGE_KIND.CHATROOM}/>
      <DisplayUserName userName={displayUserName()} />
      <DisplayRank />
      <ChatRoom/>
      <Footer />
    </div>
  );
}

export { SP_ChatRoomPage };
