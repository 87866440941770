import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

function CommonDialog(props) {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={props.doNo}
                aria-labelledby="common-dialog-title"
                aria-describedby="common-dialog-description"
            >
                <DialogContent>
                    {props.msg}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.doNo} color="primary">
                        No
                    </Button>
                    <Button onClick={props.doYes} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default CommonDialog