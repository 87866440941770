import React, { useState } from "react";
import "./sideMenu.css";
import icon from "../../../Images/pic3.png";
import { Button, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PAGE_KIND, USER_KIND } from "../../../util/define";
import { ModelLogin } from "../../../util/web_api";
import { useRecoilValue } from "recoil";
import { watchingUserMailaddress as recoil } from "../../../recoil/atom";
import { CompanyLogin } from "../../../util/web_api";
import MenuIcon from '@mui/icons-material/Menu';


function SideMenu(props) {
  const navigation = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const watchingUserMailaddress = useRecoilValue(recoil);
  const token = localStorage.getItem("token");
  const clientData = JSON.parse(token);

  const handleClickLogout = () => {
    localStorage.removeItem("token");
    navigation("/")
  }

  const handleClickSendChatButton = () => {
    console.log(props);

    const poster = {
      ModelMailaddress: watchingUserMailaddress,
      ClientMailaddress: clientData.mailaddress,
      UserKind: clientData.userKind
    }

    console.log(poster);
    // PostChatRoomByMailaddress(poster, (res) => {
    //   console.log(res.data);
    //   navigation('/chatRoom');
    // }, (e) => {
    //   console.log(e);
    // })
  }

  const displaySendChatButton = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      return <Button variant="outlined" color="inherit" style={{ marginTop: "20px" }} onClick={handleClickSendChatButton}>このモデルにチャットを送る</Button>;
    } else {
      return <Button variant="outlined" color="inherit" style={{ marginTop: "20px" }}>会員登録する</Button>
    }
  }

  const onClickMyPage = () => {
    if (clientData.userKind == USER_KIND.MODEL) {
      ModelLogin(clientData.mailaddress, clientData.password, (res) => {
        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
        localStorage.setItem('token', JSON.stringify(token));
        navigation("/model", { state: { resdata: res.data } });
      }, (e) => {
        navigation("/account/login");
      })
    } else {
      CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
        localStorage.setItem('token', JSON.stringify(token));
        navigation("/hairdresser", { state: { resdata: res.data } });
      }, (e) => {
        navigation("/account/login");
      })
    }
  }

  console.log("aaa");

  const transKind = () => {
    console.log(props.pageKind);
    switch (props.pageKind) {
      case PAGE_KIND.MYPAGE:
        return (
          <React.Fragment>
            <Drawer
              anchor={'right'}
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}>
              <Box sx={{ width: "50vw" }}>
                <img className="spMainMenuMyPageIcon" src={icon} />
                <List>
                  <ListItem style={{backgroundColor:"lightblue"}}>
                    <ListItemButton>
                      <ListItemText onClick={onClickMyPage}>マイページ</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={() => navigation('/chatRoom')}>チャット</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>オファー一覧</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={handleClickLogout}>ログアウト</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>その他のメニューが並びます</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        )
      case PAGE_KIND.PROFILE:
        return (
          <React.Fragment>
            <Drawer
              anchor={'right'}
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}>
              <Box sx={{ width: "50vw" }}>
                <img className="spMainMenuMyPageIcon" src={icon} />
                <List>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={onClickMyPage}>マイページ</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={() => navigation('/chatRoom')}>チャット</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>オファー一覧</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={handleClickLogout}>ログアウト</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>その他のメニューが並びます</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        )
      case PAGE_KIND.CHATROOM:
        return (
          <React.Fragment>
            <Drawer
              anchor={'right'}
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}>
              <Box sx={{ width: "50vw" }}>
                <img className="spMainMenuMyPageIcon" src={icon} />
                <List>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={onClickMyPage}>マイページ</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem style={{backgroundColor:"lightblue"}}>
                    <ListItemButton>
                      <ListItemText onClick={() => navigation('/chatRoom')}>チャット</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>オファー一覧</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={handleClickLogout}>ログアウト</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>その他のメニューが並びます</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        )
      case PAGE_KIND.CHAT:
        return (
          <React.Fragment>
            <Drawer
              anchor={'right'}
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}>
              <Box sx={{ width: "50vw" }}>
                <img className="spMainMenuMyPageIcon" src={icon} />
                <List>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={onClickMyPage}>マイページ</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem style={{backgroundColor:"lightblue"}}>
                    <ListItemButton>
                      <ListItemText onClick={() => navigation('/chatRoom')}>チャット</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>オファー一覧</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText onClick={handleClickLogout}>ログアウト</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton>
                      <ListItemText>その他のメニューが並びます</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        )
      default:
        return;
    }
  }

  return (
    <React.Fragment>
      <MenuIcon
        onClick={() => setIsDrawerOpen(true)}
        style={{ position: "fixed", marginLeft: "85vw", marginTop: "8px" }}
        color='gray'
      />
      {transKind()}
    </React.Fragment>
  );
}

export { SideMenu };
