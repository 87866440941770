import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { HeaderTop } from "../../control/header/header";
import { Footer } from "../../control/footer/footer";
import "./TermsService.css"

function TermsService() {

  const navigation = useNavigate("/test");

  return (
    <div>
      <HeaderTop />
      <div className="sentence">
        <h1>利用規約</h1>
        <h2>第1条 はじめに</h2>
        <div>
          1<br />
          Coupe（以下「本サービス」といいます）とは、株式会社Coupe（以下「弊社」といいます）が運営する、美容師とサロンモデルのマッチングサービスです。
          本サービスをご利用になるにはこのCoupe利用規約（以下「本規約」といいます）に同意のうえご利用ください。本サービスの利用者（以下「ユーザー」といいます）は、
          本規約の全ての記載内容について同意するものとします。<br />
          2<br />
          弊社は、弊社の判断により、本規約をいつでも任意の理由で変更することができるものとします。
          変更後の本規約は、弊社が別途定める場合を除いて、本サービス上に表示した後に初めてユーザーが本サービスを利用した時点より効力を生じるものとします。 <br />
          3<br />
          本サービスには、本規約およびその他の規約等において、利用条件が規定されています。その他の規約等は名称の如何に関わらず本規約の一部を構成するものとします。<br />
          4<br />
          本規約の規定とその他の規約等の規定が矛盾する場合は、弊社が別途定める場合を除いて、本規約の規定が優先して適用されるものとします。
        </div>
        <h2>第2条 アカウント登録</h2>
        <div>
          1<br />
          本サービスを利用する場合は、本規約の内容に同意した上で、利用規約に基づき、アカウント登録を行うものとします。<br />
          2<br />
          未成年者が本サービスを利用する場合には、親権者の同意を得て利用するものとします。<br />
          3<br />
          ユーザーが次の各号のいずれかに該当する場合には、利用をお断りする場合があります。<br />
          {"("}1{")"}<br />
          本条第1項の方法によらずにアカウント登録を行った場合<br />
          {"("}2{")"}<br />
          過去に本規約に違反したことを理由として何らかの処分を受けた者である場合<br />
          {"("}3{")"}<br />
          弊社が提供する他のサービスにおいて何らかの処分を受けたことがある場合<br />
          {"("}4{")"}<br />
          その他弊社が不適切と判断した場合
        </div>
        <h2>第3条 アカウントの管理</h2>
        <div>
          1<br />
          ユーザーは、自己のアカウント登録に必要な所定の情報（メールアドレス、パスワードを含みますがそれに限られません。）の不正利用の防止に努めるとともに、
          その管理について一切の責任を持つものとします。<br />
          2<br />
          ユーザーはいかなる場合においても、アカウントを第三者に譲渡または貸与することはできません。<br />
          3<br />
          ユーザーは1人につき1つのアカウントを保有するものとします。1人が複数のアカウントを保有すること、複数人が1つのアカウントを共同して保有することはできません。
        </div>
        <h2>第4条 利用環境の整備</h2>
        <div>
          1<br />
          ユーザーは、本サービスを利用するために必要なあらゆる機器、ソフトウェア、通信手段を自己の責任と費用において、適切に整備するものとします。<br />
          2<br />
          ユーザーは自己の利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。<br />
          3<br />
          弊社はユーザーの利用環境について一切関与せず、また一切の責任を負いません。
        </div>
        <h2>第5条 本サービスの内容</h2>
        <div>
          1<br />
          ユーザーが本サービスに登録すると美容師ユーザーがサロンモデルに撮影協力依頼を出すこと、サロンモデルユーザーがその依頼に応じること、
          美容師、サロンモデル双方のユーザーが依頼内容に従って相互に連絡をとりあうことができます。<br />
          2<br />
          弊社はあくまで前項の機能やそれを表示する場所を提供するのみで、実際の美容室において撮影をすること、美容室に行くこと、依頼がキャンセルされる等には関与せず責任も負いません。
        </div>
        <h2>第6条 サービスの変更点</h2>
        <div>
          弊社は、弊社の都合により、本サービスをいつでも任意の理由で、追加、変更、中断、終了することができます。
        </div>
        <h2>第7条 利用料金等</h2>
        <div>
          1<br />
          本サービスは原則として無料とします。<br />
          2<br />
          弊社が、本サービスの使用の対価を求める場合にはユーザーに事前に通知し同意を得るものとします。
        </div>
        <h2>第8条 ユーザーの権利と責任</h2>
        <div>
          1<br /><br />
          ユーザーは、ユーザー自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果について一切の責任を負います。　<br />
          2<br />
          投稿情報（サロンモデルに撮影依頼を出すことやそれに応じて連絡を取り合うなどの本サービスに投稿するすべての情報のこと。以下同じ。）に関する権利は、投稿したユーザーに帰属します。
          ーザーは、弊社に対して、投稿情報を本サービスの提供に必要な範囲内のライセンスを付与するものとします。<br />
          3<br />
          投稿情報に関する責任は、ユーザー自身にあります。ユーザーは自ら投稿情報を適切に管理するものとします。<br />
          4<br />
          ユーザーは、弊社に対し、当該投稿情報が弊社または第三者の権利を侵害していないことを保証するものとします。ユーザーが弊社または第三者の名誉を毀損した場合、
          プライバシー権を侵害した場合、許諾なく弊社または第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合その他弊社または第三者の権利を侵害した場合は、
          当該ユーザーは自己の責任と費用において解決するものとします。<br />
          5<br />
          ユーザーは、投稿情報について、ユーザー自身の自己責任においてバックアップをとるものとします。
        </div>
        <h2>第9条 個人情報の取り扱い</h2>
        <div>
          弊社は、個人情報を「プライバシーポリシー」に基づき、適切に取り扱うものとします。
        </div>
        <h2>第10条 アカウント削除</h2>
        <div>
          1<br />
          ユーザーが退会を希望する場合には、当該ユーザーは、Coupeアカウント利用規約に基づき、アカウント削除を行うものとします。<br />
          2<br />
          弊社は、ユーザーが次の各号に掲げるいずれかに該当する場合には、弊社の判断によって、強制的にユーザーのアカウントを削除して本サービスの利用をお断りすることがあります。<br />
          {"("}1{")"}<br />            第2条第1項の方法によらずにアカウント登録を行ったことが明らかとなった場合<br />
          {"("}2{")"}<br />
          本規約に違反した場合<br />
          {"("}3{")"}<br />
          弊社が提供する他のサービスにおいて何らかの処分を受けたことがある場合<br />
          {"("}4{")"}<br />
          その他弊社が不適切と判断した場合
        </div>
        <h2>第11条 禁止事項</h2>
        <div>
          ユーザーは、本サービスの利用にあたり、次に掲げる行為を行ってはならないものとします。<br />
          (1)<br />
          弊社もしくは第三者の著作権、商標権等の知的財産権を侵害する行為、または侵害するおそれのある行為。<br />
          (2)<br />
          弊社もしくは第三者の財産、プライバシーもしくは肖像権を侵害する行為、または侵害するおそれのある行為。<br />
          (3)<br />
          弊社もしくは第三者を不当に差別もしくは誹謗中傷し、弊社もしくは第三者への不当な差別を助長し、またはその名誉もしくは信用を毀損する行為。<br />
          (4)<br />
          自分以外の人物を名乗ったり、代表権や代理権がないにもかかわらずあるものと装ったり、または他の人物や組織と提携、協力関係にあると偽る行為。<br />
          (5)<br />
          詐欺、規制薬物の濫用、児童売買春、預貯金口座および携帯電話の違法な売買等の犯罪に結びつく、または結びつくおそれのある行為。<br />
          (6)<br />
          わいせつ、児童ポルノもしくは児童虐待に相当する投稿情報を投稿または表示する行為、またはこれらの情報を収録した媒体を販売する行為、またはその送信、表示、販売を想起させる広告を投稿または表示する行為。 <br />
          (7)<br />
          性行為、わいせつな行為等を目的として利用する行為。<br />
          (8)<br />
          面識のない異性との出会い等を目的として利用する行為。<br />
          (9)<br />
          違法な賭博・ギャンブルを行わせ、または違法な賭博・ギャンブルへの参加を勧誘する行為。<br />
          (10)<br />
          違法行為（けん銃等の譲渡、爆発物の製造、児童ポルノの提供、公文書偽造、殺人、脅迫等）を請け負い、仲介し、または誘引する行為。<br />
          (11)<br />
          人を自殺に誘引または勧誘する行為、または他者に危害の及ぶおそれの高い自殺の手段等を紹介するなどの行為。<br />
          (12)<br />
          人の殺害・傷害現場、死体、その他残虐な行為を撮影した投稿情報、または社会通念上他人に嫌悪感を抱かせたり、他のユーザーが不快を感じる可能性が高いと弊社が判断する投稿情報を投稿または表示する行為。<br />
          (13)<br />
          無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為。<br />
          (14)<br />
          アダルトサイト、ワンクリック詐欺サイト、ウィルス等の有害なコンピュータプログラム等を流布させることを目的とするサイト等弊社が不適切と判断するサイトに誘導する投稿情報を投稿または表示する行為
          （単にリンクを張る行為を含む。）。<br />
          (15)<br />
          1人が複数のアカウントを保有する行為または複数人が1つのアカウントを共同して保有する行為。<br />
          (16)<br />
          営利を目的として利用する行為。<br />
          (17)<br />
          サロンモデル撮影以外の目的で投稿情報を表示する行為。ただし、弊社が特に認めた場合を除く。<br />
          (18)<br />
          虚偽の情報を本サービスの登録に必要な情報に登録および本サービスに投稿する行為。<br />
          (19)<br />
          弊社が悪質と判断する悪戯（一度に大量の約束を取り付けるなど）による投稿を行う行為。<br />
          (20)<br />
          弊社の設備に蓄積された情報を不正に書き換え、または消去する行為。<br />
          (21)<br />
          ウィルス等の有害なコンピュータプログラム等を送信または掲載する行為。<br />
          (22)<br />
          弊社または第三者のサーバーに負担をかける行為、もしくは、本サービスの運営やネットワーク・システムに支障を与える行為、またはこれらのおそれのある行為。<br />
          (23)<br />
          その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する目的でリンクを貼る行為。<br />
          (24)<br />
          法令、公序良俗に違反し、または他者の権利を侵害すると弊社が判断する行為。<br />
          (25)<br />
          その他、他の使用者に迷惑をかける行為等の弊社が不適切と判断する行為。
        </div>
        <h2>第12条 広告等</h2>
        <div>
          1<br />
          弊社は、本サービス上に広告を表示することができるものとします。<br />
          2<br />
          弊社は、ユーザーに対して新サービスの告知、広告配信、サービス運営上の事務連絡、その他情報の提供をメールなどの手段で直接行うことができるものとします。ただし、
          ユーザーが情報の提供を希望しない旨を、事前または事後に弊社所定の方法で通知した場合は、本サービスの提供に必要な場合を除いて、情報の提供を行わないものとします。
        </div>
        <h2>第13条 権利譲渡の禁止</h2>
        <div>
          ユーザーは、本規約に定める場合を除くほか、本規約に基づくいかなる権利または義務も第三者に移転、譲渡その他の処分を行うことはできません。
        </div>
        <h2>第14条 免責</h2>
        <div>
          1<br />
          弊社は、ユーザーの通信や活動に関与しません。万一他のユーザーまたは第三者（美容室を含みますがそれに限られません）との紛争があった場合でも、当事者間で解決するものとし、弊社はその責任を負いません。<br />
          2<br />
          本サービスの追加、変更、中断、終了によりユーザーに生じた損害について、弊社は、故意または重過失による場合を除き、いかなる責任も負いません。<br />
          3<br />
          アクセス過多、その他予期せぬ要因で表示速度の低下や障害等によって生じた損害について、弊社は、いかなる責任も負いません。<br />
          4<br />
          弊社は、投稿情報を監視したり、保存する義務を負いません。<br />
          5<br />
          弊社は、本サービスにおける一切の動作保証、使用目的への適合性の保証、使用結果に関わる的確性や信頼性の保証をせず、かついかなる内容の瑕疵担保責任も負いません。<br />
          6<br />
          弊社は、次に掲げる場合には、当該投稿情報の内容を閲覧したり、保存したり、第三者に開示することができるものとします。弊社は、それによって生じたいかなる損害についても、一切責任を負いません。<br />
          (1)<br />
          本サービスの技術的不具合の原因を解明し、解消するために必要な場合。<br />
          (2)<br />
          裁判所や警察などの公的機関から、法令に基づく正式な照会を受けた場合。<br />
          (3)<br />
          本規約に違反する行為またはそのおそれのある行為が行われ、投稿情報の内容を確認する必要が生じたと弊社が判断した場合。<br />
          (4)<br />
          人の生命、身体および財産などに差し迫った危険があり、緊急の必要性があると弊社が判断した場合。<br />
          (5)<br />
          その他本サービスを適切に運営するために必要が生じた場合。<br />
          7<br />
          弊社は、本規約に違反する行為またはそのおそれのある行為が行われたと信じるに足りる相当な理由があると判断した場合には、当該行為を行ったユーザーに対して、事前の通知をおこなうことなく、
          投稿情報の削除やサービスの利用制限等の弊社が必要と考える措置を講じる場合がありますが、それによって生じたいかなる損害についても、故意または重過失による場合を除き、一切責任を負いません。
          ーザーは、弊社が本項の規定に基づいて行った措置に対して、異議を申し立てないものとします。<br />
          8<br />
          本規約が消費者契約法（平成12年法律第61号）第2条第3項の消費者契約に該当する場合には、本規約のうち、弊社の損害賠償責任を完全に免責する規定は適用されないものとします。
          この場合において利用者に発生した損害が弊社の債務不履行または不法行為に基づくときは、弊社は、当該利用者が弊社に本サービスの対価として支払った一月分の料金を上限として
          損害賠償責任を負うものとします。ただし、弊社に故意または重過失がある場合は除きます。<br />
          9<br />
          本規約の定めにかかわらず、いかなる場合においても弊社は、本サービスに関し次に掲げる損害については一切の責任を負いません。<br />
          (1)<br />
          弊社の責に帰すべからざる事由から生じた損害。<br />
          (2)<br />
          弊社の予見の有無に関わらず、特別の事情から生じた損害。<br />
          (3)<br />
          サーバーのメンテナンスなど本サービスの提供にあたって必要不可欠な行為によって生じた損害。
        </div>
        <h2>第15条 準拠法および管轄裁判所</h2>
        <div>
          1<br />
          本規約の準拠法は、日本法とします。<br />
          2<br />
          本サービスまたは本規約に関してユーザーとの間で疑義または争いが生じた場合には、誠意をもって協議することとしますが、
          それでもなお解決しない場合には、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </div>
        <h2>第16条 その他</h2>
        <div>
          1<br />
          本規約の一部が法令に基づいて無効と判断されても、その他の規定は有効とします。<br />
          2<br />
          本規約の一部があるユーザーとの関係で無効とされ、または取り消された場合でも、本規約はその他のユーザーとの関係では有効とします。
        </div>
      </div>
      <Footer />
    </div>
  );
}

export { TermsService };
