import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderTop } from '../../control/header/header';
import { Footer } from '../../control/footer/footer';
import './hsRegist.css';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { RegistHairdresser } from '../../../util/web_api';

function HairStylist_Regist(children) {
  const navigation = useNavigate('');
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [kFirstName, setKFirstName] = useState('');
  const [kSecondName, setKSecondName] = useState('');
  const [mailaddress, setMailaddress] = useState('');
  const [saloneAddress1, setSaloneAddress1] = useState('福岡県');
  const [saloneAddress2, setSaloneAddress2] = useState('');
  const [saloneAddress3, setSaloneAddress3] = useState('');
  const [homePageURL, setHomePageURL] = useState('');
  const [instagramID, setInstagramID] = useState('');
  const [image, setImage] = useState('');

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeRePassword = (e) => {
    setRePassword(e.target.value);
  };

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeSecondName = (e) => {
    setSecondName(e.target.value);
  };

  const handleChangeKFirstName = (e) => {
    setKFirstName(e.target.value);
  };

  const handleChangeKSecondName = (e) => {
    setKSecondName(e.target.value);
  };

  const handleChangeMailaddress = (e) => {
    setMailaddress(e.target.value);
  };

  const handleChangeSaloneAddress1 = (e) => {
    setSaloneAddress1(e.target.value);
  };

  const handleChangeSaloneAddress2 = (e) => {
    setSaloneAddress2(e.target.value);
  };

  const handleChangeSaloneAddress3 = (e) => {
    setSaloneAddress3(e.target.value);
  };

  const handleChangeHomePageURL = (e) => {
    setHomePageURL(e.target.value);
  };

  const handleChangeInstagramID = (e) => {
    setInstagramID(e.target.value);
  };

  const handleChoiceImage = (e) => {
    const files = e.target.files;
    console.log(files);
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.log('0以下');
      setImage('');
    }
  };

  const handlePostNewHairdresser = () => {
    const Poster = {
      Password: password,
      FirstName: firstName,
      KFirstName: kFirstName,
      SecondName: secondName,
      KSecondName: kSecondName,
      Mailaddress: mailaddress,
      SaloneAddress1: saloneAddress1,
      SaloneAddress2: saloneAddress2,
      SaloneAddress3: saloneAddress3,
      HomePageURL: homePageURL,
      InstagramId: instagramID,
      ProfilePhoto: image,
    };

    console.log(Poster);
    RegistHairdresser(
      Poster,
      (res) => {
        console.log(res.data);
        navigation('/');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <div>
      <HeaderTop />
      <div className="hsRegistTitle">美容師登録フォーム</div>
      <div className="RegistForm">
        <div className="FormTitle">
          登録フォーム<span className="hs_attention">*は必須項目です</span>
        </div>
        <div>
          <div className="hs_inputInfo">
            <TextField
              onChange={handleChangeFirstName}
              style={{ width: '15vw' }}
              label="姓"
              variant="filled"
              required
            />
            <TextField
              onChange={handleChangeSecondName}
              style={{ width: '15vw', marginLeft: '2vw' }}
              label="名"
              variant="filled"
              required
            />
          </div>
          <div className="hs_inputInfo">
            <TextField
              onChange={handleChangeKFirstName}
              style={{ width: '15vw' }}
              label="セイ"
              variant="filled"
              required
            />
            <TextField
              onChange={handleChangeKSecondName}
              style={{ width: '15vw', marginLeft: '2vw' }}
              label="メイ"
              variant="filled"
              required
            />
          </div>
          <div className="hs_inputInfo">
            <TextField
              onChange={handleChangeMailaddress}
              style={{ width: '32vw' }}
              label="メールアドレス"
              variant="filled"
              required
            />
          </div>
          <div className="hs_inputInfo">
            <TextField
              onChange={handleChangePassword}
              style={{ width: '15vw' }}
              label="パスワード"
              variant="filled"
              type="password"
              required
            />
            <TextField
              onChange={handleChangeRePassword}
              style={{ width: '15vw', marginLeft: '2vw' }}
              label="パスワード(再入力)"
              type="password"
              variant="filled"
              required
            />
          </div>
          <div className="hs_inputInfo">
            <TextField
              onChange={handleChangeSaloneAddress1}
              style={{ width: '10vw' }}
              label="サロン住所(都道府県)"
              variant="filled"
              value={'福岡県'}
              disabled
              required
            />
            <TextField
              onChange={handleChangeSaloneAddress2}
              style={{ width: '15vw', marginLeft: '2vw' }}
              label="サロン住所(市区町村)"
              variant="filled"
              required
            />
            <TextField
              onChange={handleChangeSaloneAddress3}
              style={{ width: '25vw', marginLeft: '2vw' }}
              label="サロン住所(番地、建物名)"
              variant="filled"
              required
            />
          </div>
          <div className="hs_inputInfo">
            <TextField
              onChange={handleChangeHomePageURL}
              style={{ width: '15vw' }}
              label="お店HPのURL"
              variant="filled"
              required
            />
            <TextField
              onChange={handleChangeInstagramID}
              style={{ width: '15vw', marginLeft: '2vw' }}
              label="Instagramアカウント名"
              variant="filled"
              required
            />
          </div>
          <div className="hs_inputInfo">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleChoiceImage(e)}
            />
            <div>アプリ加工、プリクラではないものを添付してください。</div>
          </div>
          <Link
            underline="hover"
            target="_blank"
            style={{ cursor: 'pointer' }}
            to="/termsService"
          >
            利用規約
          </Link>
          <FormGroup className="c_inputInfo" style={{ width: '170px' }}>
            <FormControlLabel control={<Checkbox />} label="利用規約に同意" />
          </FormGroup>
          <Button
            onClick={handlePostNewHairdresser}
            variant="contained"
            size="large"
          >
            登録する(まだ登録は完了しません)
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export { HairStylist_Regist };
