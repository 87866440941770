import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Footer } from "../../control/footer/footer";
import { HeaderTop } from "../../control/header/header";
import { DisplayRank } from "../../control/displayRank/displayRank";
import { DisplayUserName } from "../../control/displayUserName/displayUserName";
import { PAGE_KIND } from "../../../util/define";
import { MyPage } from "../../control/hairdresserMyPage/myPage";
import { SideMenu } from "../../control/sideMenu/sideMenu";

function SP_HairDresserPage() {

  const navigation = useNavigate("");
  const location = useLocation();

  return (
    <div>
        <HeaderTop/>
        <SideMenu pageKind={PAGE_KIND.MYPAGE}/>
        <DisplayUserName userName={location.state.resdata.mailaddress}/>
        <DisplayRank/>
        <MyPage hairdresserData={location.state.resdata} />
        <Footer/>
    </div>
  );
}

export {SP_HairDresserPage};
