import React from "react";
import "./sideMenu.css";
import icon from "../../../Images/pic3.png";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGE_KIND, USER_KIND } from "../../../util/define";
import { GetModelByMailaddress, ModelLogin } from "../../../util/web_api";
import { useRecoilValue } from "recoil";
import { watchingUserMailaddress as recoil } from "../../../recoil/atom";
import { CompanyLogin } from "../../../util/web_api";


function SideMenu(props) {
  const navigation = useNavigate();
  const location = useLocation();

  const watchingUserMailaddress = useRecoilValue(recoil);
  const token = localStorage.getItem("token");
  const clientData = JSON.parse(token);

  const handleClickLogout = () => {
    localStorage.removeItem("token");
    navigation("/")
  }

  const handleClickSendChatButton = () => {
    console.log(props);

    const poster = {
      ModelMailaddress: watchingUserMailaddress,
      ClientMailaddress: clientData.mailaddress,
      UserKind: clientData.userKind
    }

    console.log(poster);
    // PostChatRoomByMailaddress(poster, (res) => {
    //   console.log(res.data);
    //   navigation('/chatRoom');
    // }, (e) => {
    //   console.log(e);
    // })
  }

  const handleClickMakeOfferButton = () => {
    console.log(location);
    GetModelByMailaddress(location.state.resdata.mailaddress, (res) => {
      navigation('/makeOffer', { state: { resdata: res.data } });
    }, (e) => {
      console.warn(e.response.data);
    })
  }

  const displaySendChatButton = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      return <Button variant="outlined" color="inherit" style={{ marginTop: "20px" }} onClick={() => handleClickMakeOfferButton()}>オファーを送る</Button>;
    } else {
      return <Button variant="outlined" color="inherit" style={{ marginTop: "20px" }}>会員登録する</Button>
    }
  }

  const transKind = () => {
    switch (props.pageKind) {
      case PAGE_KIND.MYPAGE:
        return (
          <div className="pcSideMenuMyPage">
            <img className="pcMainMenuMyPageIcon" src={icon} />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "20px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/model", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/hairdresser", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>マイページ</Button>
            <br />
            {
              clientData.userKind == USER_KIND.HAIRDRESSER
                ?
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    if (clientData.userKind == USER_KIND.HAIRDRESSER) {
                      CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                        localStorage.setItem('token', JSON.stringify(token));
                        navigation("/MySalone");
                      }, (e) => {
                        navigation("/account/login");
                      })
                    }
                  }}>マイサロン
                </Button>
                : null
            }<br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => navigation('/chatRoom')}>チャット</Button>
            <br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>オファー一覧</Button>
            <br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>美容室を探す</Button><br />
            <Button variant="outlined" color="inherit" style={{ marginTop: "10px" }}>求人を探す</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={handleClickLogout}>ログアウト</Button>
          </div>
        )
      case PAGE_KIND.PROFILE:
        return (
          <div className="pcSideMenuMyPage">
            <img className="pcMainMenuMyPageIcon" src={icon} />
            {displaySendChatButton()}<br />
            <Button variant="outlined" color="inherit" style={{ marginTop: "40px" }} onClick={() => {
              if (clientData.userKind == USER_KIND.MODEL) {
                ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                  const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                  localStorage.setItem('token', JSON.stringify(token));
                  navigation("/model", { state: { resdata: res.data } });
                }, (e) => {
                  navigation("/account/login");
                })
              } else {
                CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                  const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                  localStorage.setItem('token', JSON.stringify(token));
                  navigation("/hairdresser", { state: { resdata: res.data } });
                }, (e) => {
                  navigation("/account/login");
                })
              }
            }}>マイページ</Button><br />
            {
              clientData.userKind == USER_KIND.HAIRDRESSER
                ?
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    if (clientData.userKind == USER_KIND.HAIRDRESSER) {
                      CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                        localStorage.setItem('token', JSON.stringify(token));
                        navigation("/MySalone");
                      }, (e) => {
                        navigation("/account/login");
                      })
                    }
                  }}>マイサロン
                </Button>
                : null
            }<br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => navigation('/chatRoom')}>チャット</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>オファー一覧</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>美容室を探す</Button><br />
            <Button variant="outlined" color="inherit" style={{ marginTop: "10px" }}>求人を探す</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={handleClickLogout}>ログアウト</Button>
          </div>
        )
      case PAGE_KIND.CHATROOM:
        return (
          <div className="pcSideMenuMyPage">
            <img className="pcMainMenuMyPageIcon" src={icon} />
            <Button variant="outlined" color="inherit" style={{ marginTop: "20px" }} onClick={() => {
              if (clientData.userKind == USER_KIND.MODEL) {
                ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                  const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                  localStorage.setItem('token', JSON.stringify(token));
                  navigation("/model", { state: { resdata: res.data } });
                }, (e) => {
                  navigation("/account/login");
                })
              } else {
                CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                  const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                  localStorage.setItem('token', JSON.stringify(token));
                  navigation("/hairdresser", { state: { resdata: res.data } });
                }, (e) => {
                  navigation("/account/login");
                })
              }
            }}>マイページ</Button><br />
            {
              clientData.userKind == USER_KIND.HAIRDRESSER
                ?
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    if (clientData.userKind == USER_KIND.HAIRDRESSER) {
                      CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                        localStorage.setItem('token', JSON.stringify(token));
                        navigation("/MySalone");
                      }, (e) => {
                        navigation("/account/login");
                      })
                    }
                  }}>マイサロン
                </Button>
                : null
            }<br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => navigation('/chatRoom')}>チャット</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>オファー一覧</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>美容室を探す</Button><br />
            <Button variant="outlined" color="inherit" style={{ marginTop: "10px" }}>求人を探す</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={handleClickLogout}>ログアウト</Button>
          </div>
        )
      case PAGE_KIND.CHAT:
        return (
          <div className="pcSideMenuMyPage">
            <img className="pcMainMenuMyPageIcon" src={icon} />
            <Button variant="outlined" color="inherit" style={{ marginTop: "20px" }} onClick={() => {
              if (clientData.userKind == USER_KIND.MODEL) {
                ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                  const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                  localStorage.setItem('token', JSON.stringify(token));
                  navigation("/model", { state: { resdata: res.data } });
                }, (e) => {
                  navigation("/account/login");
                })
              } else {
                CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                  const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                  localStorage.setItem('token', JSON.stringify(token));
                  navigation("/hairdresser", { state: { resdata: res.data } });
                }, (e) => {
                  navigation("/account/login");
                })
              }
            }}>マイページ</Button><br />
            {
              clientData.userKind == USER_KIND.HAIRDRESSER
                ?
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    if (clientData.userKind == USER_KIND.HAIRDRESSER) {
                      CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                        localStorage.setItem('token', JSON.stringify(token));
                        navigation("/MySalone");
                      }, (e) => {
                        navigation("/account/login");
                      })
                    }
                  }}>マイサロン
                </Button>
                : null
            }<br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => navigation('/chatRoom')}>チャット</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>オファー一覧</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>美容室を探す</Button><br />
            <Button variant="outlined" color="inherit" style={{ marginTop: "10px" }}>求人を探す</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={handleClickLogout}>ログアウト</Button>
          </div>
        )
      case PAGE_KIND.SALONE:
        return (
          <div className="pcSideMenuMyPage">
            <img className="pcMainMenuMyPageIcon" src={icon} />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "20px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/model", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/hairdresser", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>マイページ</Button>
            <br />
            {
              clientData.userKind == USER_KIND.HAIRDRESSER
                ?
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    if (clientData.userKind == USER_KIND.HAIRDRESSER) {
                      CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                        const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                        localStorage.setItem('token', JSON.stringify(token));
                        navigation("/MySalone");
                      }, (e) => {
                        navigation("/account/login");
                      })
                    }
                  }}>マイサロン
                </Button>
                : null
            }<br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => navigation('/chatRoom')}>チャット</Button>
            <br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/offerList", { state: { resdata: res.data } });
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>オファー一覧</Button>
            <br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (clientData.userKind == USER_KIND.MODEL) {
                  ModelLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                } else {
                  CompanyLogin(clientData.mailaddress, clientData.password, (res) => {
                    const token = { userKind: res.data.userKind, mailaddress: res.data.mailaddress, password: res.data.password }
                    localStorage.setItem('token', JSON.stringify(token));
                    navigation("/salonePage");
                  }, (e) => {
                    navigation("/account/login");
                  })
                }
              }}>美容室を探す</Button><br />
            <Button variant="outlined" color="inherit" style={{ marginTop: "10px" }}>求人を探す</Button><br />
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginTop: "10px" }}
              onClick={handleClickLogout}>ログアウト</Button>
          </div>
        )
      default:
        return;
    }
  }

  return (
    <React.Fragment>
      {transKind()}
    </React.Fragment>
  );
}

export { SideMenu };
