import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import banner from "./../../../../Images/image10.jpg";
import "./bTop.css";

function Banner() {

  const navigation = useNavigate("/test");

  return (
    <div className="spbanner">
        <div className="spsiteName">f.m.m</div>
        <div className="spbannerElements">
                <img src={banner} className="spbannerImage"/>
        </div>
        <div className="spbannerElementsButton">
                <div>福岡のサロンモデルがすぐ見つかる！</div>
        </div>
    </div>
  );
}

export {Banner};
