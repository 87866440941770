import React from "react";
import { Footer } from "../../control/footer/footer";
import { Form } from "../../control/loginForm/fLogin";
import { HeaderTop } from "../../control/header/header";

function Login() {
    return (
        <div>
            <HeaderTop/>
            <Form/>
            <div style={{marginTop: "100px"}}>
                <Footer/>
            </div>
        </div>
    );
}

export {Login};
