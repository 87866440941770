import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import "./mTop.css";

function Models() {

  const navigation = useNavigate("/test");

  return (
    <div className="models">
        <div className="mHeadLine">人気のサロンモデル</div>
        <div className="modelsPhoto">女性モデルの写真が並びます</div>
        <Button variant="outlined" color="error" className="mButton">女性モデルをもっと見る</Button>
        <div className="modelsPhoto">男性モデルの写真が並びます</div>
        <Button variant="outlined" className="mButton">男性モデルをもっと見る</Button>
    </div>
  );
}

export {Models};
