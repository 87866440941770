import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderTop } from '../../control/header/header';
import { Footer } from '../../control/footer/footer';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Box,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import { RegistHairdresser, SendOffer } from '../../../util/web_api';
import { DisplayUserName } from '../../control/displayUserName/displayUserName';
import './makeOffer.css';
import Image1 from '../../../Images/Rank1.jpg';
import { SideMenu } from '../../control/sideMenu/sideMenu';
import { PAGE_KIND } from '../../../util/define';
import CommonDialog from '../../control/commonDialog/commonDialog';

function MakeOffer(children) {
  const navigation = useNavigate('');
  const location = useLocation();

  const [shootingUsage, setShootingUsage] = useState('');
  const [shootingDate, setShootingDate] = useState('');
  const [shootingTime, setShootingTime] = useState(0);
  const [shootingPlace, setShootingPlace] = useState('');
  const [shootingAddress, setShootingAddress] = useState('');
  const [reward, setReward] = useState('');
  const [withPreparation, setWithPreparation] = useState(false);
  const [withMake, setWithMake] = useState(false);
  const [withCostume, setWithCostume] = useState(false);
  const [others, setOthers] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const [isOpenConfirmationDlg, setIsOpenConfirmationDlg] = useState(false);

  const handleChangeShootingUsage = (e) => {
    setShootingUsage(e.target.value);
  };

  const handleChangeShootingDate = (e) => {
    setShootingDate(e.target.value);
  };

  const handleChangeShootingTime = (e) => {
    setShootingTime(e.target.value);
  };

  const handleChangeShootingPlace = (e) => {
    setShootingPlace(e.target.value);
  };

  const handleChangeShootingAddress = (e) => {
    setShootingAddress(e.target.value);
  };

  const handleChangeReward = (e) => {
    setReward(e.target.value);
  };

  const handleChangeWithPreparation = (e) => {
    setWithPreparation(e.target.value);
  };

  const handleChangeWithMake = (e) => {
    setWithMake(e.target.value);
  };

  const handleChangeWithCostume = (e) => {
    setWithCostume(e.target.value);
  };

  const handleChangeOthers = (e) => {
    setOthers(e.target.value);
  };

  const handleChangeIsAgree = (e) => {
    setIsAgree(e.target.value);
  };

  const displayUserName = () => {
    const token = localStorage.getItem('token');
    if (token != null) {
      const clientData = JSON.parse(token);
      return clientData.mailaddress;
    } else {
      return 'ゲスト';
    }
  };

  const handleClickSendOfferButton = () => {
    const token = localStorage.getItem('token');
    const clientData = JSON.parse(token);
    const poster = {
      RequesterMailaddress: clientData.mailaddress,
      ClientMailaddress: location.state.resdata.mailaddress,
      ShootingUsage: shootingUsage,
      ShootingDate: new Date(shootingDate),
      ShootingTime: shootingTime,
      ShootingPlace: shootingPlace,
      ShootingAddress: shootingAddress,
      Reward: reward,
      WithPreparation: withPreparation,
      WithMake: withMake,
      WithCostume: withCostume,
      Others: others,
    };

    console.log(poster);
    SendOffer(
      poster,
      (res) => {
        console.log(res.data);
        navigation('/offerList');
      },
      (e) => {
        console.log(e);
      }
    );
  };

  return (
    <React.Fragment>
      <CommonDialog
        title="オファー内容確認"
        msg={
          <div>
            <table>
              <div>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                依頼先
              </div>
              <tr>
                <th align="left">依頼先のモデル</th>
                <td>
                  {location.state.resdata.firstName}{' '}
                  {location.state.resdata.secondName}
                </td>
              </tr>
              <div style={{ marginTop: '15px' }}>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                撮影概要
              </div>
              <tr>
                <th align="left">撮影用途</th>
                <td>{shootingUsage}</td>
              </tr>
              <tr>
                <th align="left">撮影日時</th>
                <td>
                  {shootingDate} から{shootingTime}時間
                </td>
              </tr>
              <tr>
                <th align="left">撮影場所</th>
                <td>{shootingPlace}</td>
              </tr>
              <tr>
                <th align="left">撮影場所の住所</th>
                <td>{shootingAddress}</td>
              </tr>
              <tr>
                <th align="left">謝礼内容</th>
                <td>{reward}</td>
              </tr>
              <div style={{ marginTop: '15px' }}>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                当日の撮影準備
              </div>
              <tr>
                <th align="left">仕込み</th>
                <td>{withPreparation ? '有' : '無'}</td>
              </tr>
              <tr>
                <th align="left">メイク</th>
                <td>{withMake ? '有' : '無'}</td>
              </tr>
              <tr>
                <th align="left">衣装</th>
                <td>{withCostume ? '有' : '無'}</td>
              </tr>
              <div style={{ marginTop: '15px' }}>
                <span
                  style={{
                    backgroundColor: 'black',
                    width: '10px',
                    display: 'inline-block',
                  }}
                >
                  |
                </span>
                その他
              </div>
              <tr>
                <th align="left">その他</th>
                <td>{others}</td>
              </tr>
            </table>
            <br />
            <div style={{ color: 'red' }}>
              ※「オファーを送る」を押すと、オファーが確定します
            </div>
          </div>
        }
        isOpen={isOpenConfirmationDlg}
        doYes={() => {
          handleClickSendOfferButton();
        }}
        doNo={() => {
          setIsOpenConfirmationDlg(false);
        }}
      />
      <HeaderTop />
      <DisplayUserName userName={displayUserName()} />
      <div className="pcViewOffer">
        <div>
          <div>
            <span
              style={{
                backgroundColor: 'black',
                width: '10px',
                display: 'inline-block',
              }}
            >
              |
            </span>
            依頼先のモデル
          </div>
          <div
            className="ViewMe"
            style={{
              marginTop: '10px',
            }}
          >
            <div className="ViewMyPhoto">
              <img
                src={location.state.resdata.profilePhoto}
                style={{ width: '12vw', height: '12vw' }}
              />
            </div>
            <div className="ViewMyDetail">
              <span className="ViewMyDetailColumn">名前</span>
              <span>
                {location.state.resdata.firstName}{' '}
                {location.state.resdata.secondName}
              </span>
              <br />
            </div>
          </div>
        </div>
        <div className="pcViewMakeOfferPart">
          <div>
            <span
              style={{
                backgroundColor: 'black',
                width: '10px',
                display: 'inline-block',
              }}
            >
              |
            </span>
            撮影概要
          </div>
          <div className="pcViewOfferUsage">
            <TextField
              label="撮影用途"
              placeholder="例) 雑誌の撮影"
              variant="filled"
              onChange={handleChangeShootingUsage}
              style={{
                marginTop: '10px',
              }}
            />
            <TextField
              type="datetime-local"
              label="撮影日時"
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeShootingDate}
              style={{
                marginTop: '10px',
                marginLeft: '10px',
              }}
            />
            <TextField
              type="number"
              label="所要時間"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">時間</InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeShootingTime}
              style={{
                width: '150px',
                marginTop: '10px',
                marginLeft: '10px',
              }}
            />
          </div>
          <div className="pcViewOfferUsage">
            <TextField
              label="撮影場所"
              placeholder="例) 警固公園"
              variant="filled"
              onChange={handleChangeShootingPlace}
              style={{
                marginTop: '10px',
              }}
            />
            <TextField
              label="撮影場所の住所"
              placeholder="例) 福岡県福岡市中央区天神２丁目２"
              variant="filled"
              onChange={handleChangeShootingAddress}
              style={{
                marginLeft: '10px',
                marginTop: '10px',
                width: '400px',
              }}
            />
          </div>
          <div className="pcViewOfferUsage">
            <TextField
              label="謝礼内容"
              placeholder="例) 3000円 + 交通費"
              variant="filled"
              onChange={handleChangeReward}
              style={{
                marginTop: '10px',
                width: '630px',
              }}
            />
          </div>
        </div>
        <div className="pcViewMakeOfferPart">
          <div>
            <span
              style={{
                backgroundColor: 'black',
                width: '10px',
                display: 'inline-block',
              }}
            >
              |
            </span>
            当日の撮影準備
          </div>
          <div className="pcViewOfferUsage">
            <TextField
              style={{
                width: '15vw',
                marginTop: '10px',
              }}
              onChange={handleChangeWithPreparation}
              label="当日の仕込みの有無"
              variant="filled"
              select
            >
              <MenuItem value={false}>無</MenuItem>
              <MenuItem value={true}>有</MenuItem>
            </TextField>
            <TextField
              style={{
                marginLeft: '10px',
                marginTop: '10px',
                width: '15vw',
              }}
              onChange={handleChangeWithMake}
              label="当日のメイクの用意"
              variant="filled"
              select
            >
              <MenuItem value={false}>無</MenuItem>
              <MenuItem value={true}>有</MenuItem>
            </TextField>
          </div>
          <div className="pcViewOfferUsage">
            <TextField
              style={{
                marginTop: '10px',
                width: '15vw',
              }}
              onChange={handleChangeWithCostume}
              label="当日の衣装の用意"
              variant="filled"
              select
            >
              <MenuItem value={false}>無</MenuItem>
              <MenuItem value={true}>有</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="pcViewMakeOfferPart" style={{ marginBottom: '40px' }}>
          <div>
            <span
              style={{
                backgroundColor: 'black',
                width: '10px',
                display: 'inline-block',
              }}
            >
              |
            </span>
            その他
          </div>
          <div className="pcViewOfferUsage">
            <TextField
              style={{
                width: '40vw',
              }}
              onChange={handleChangeOthers}
              label="その他撮影の詳細"
              variant="filled"
              multiline
              rows={3}
            />
          </div>
        </div>
        <Link
          underline="hover"
          target="_blank"
          style={{ cursor: 'pointer' }}
          to="/termsService"
        >
          利用規約
        </Link>
        <FormGroup className="c_inputInfo" style={{ width: '170px' }}>
          <FormControlLabel control={<Checkbox />} label="利用規約に同意" />
        </FormGroup>
        <Button
          onClick={() => {
            setIsOpenConfirmationDlg(true);
          }}
          variant="contained"
        >
          オファーする
        </Button>
      </div>
      <SideMenu pageKind={PAGE_KIND.MYPAGE} />
      <Footer />
    </React.Fragment>
  );
}

export { MakeOffer };
