import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import "./mTop.css";

function Models() {

  const navigation = useNavigate("/test");

  return (
    <div className="smodels">
        <div className="smHeadLine">人気のサロンモデル</div>
        <div className="smodelsPhoto">女性モデルの写真が並びます</div>
        <Button variant="outlined" color="error" className="smButton">女性モデルをもっと見る</Button>
        <div className="smodelsPhoto">男性モデルの写真が並びます</div>
        <Button variant="outlined" className="smButton">男性モデルをもっと見る</Button>
    </div>
  );
}

export {Models};
