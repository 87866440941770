import React from "react";
import { Button, Link } from "@mui/material"
import { useNavigate } from "react-router-dom";
import "./footer.css";

function Footer() {

  const navigation = useNavigate("/test");

  return (
    <div>
      <div className="spfooter">
        <div className="spFooterSiteName">f.m.m</div>
        <div className="spFooterColumnContainer">
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/Model_Regist")}>モデル 応募</Link></div>
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/HairStylist_Regist")}>美容師 新規登録</Link></div>
        </div>
        <div className="spFooterColumnContainer">
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/Company_Regist")}>企業の方はこちらから</Link></div>
        </div>
        <div className="spFooterColumnContainer">
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/inquiry")}>よくあるご質問</Link></div>
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/inquiry")}>お問い合わせフォーム</Link></div>
        </div>
        <div className="spFooterHeadLine">ログイン</div>
        <div className="spFooterColumnContainer">
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/account/login")}>モデル</Link></div>
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/account/login")}>美容師・法人</Link></div>
        </div>
        <div className="spFooterHeadLine">サービスご利用にあたって</div>
        <div className="spFooterColumnContainer">
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/privacy")}>プライバシーポリシー</Link></div>
          <div className="spFooterLinkContainer"><Link fontSize="small" color={"black"} underline="hover" style={{cursor:"pointer"}} onClick={() => navigation("/termsService")}>利用規約</Link></div>
        </div>
        <div style={{width:"80vw", marginLeft:"10vw", paddingBottom: "35px", borderBottom:"1px solid black"}}></div>
      </div>
      <div className="spbottomFooter">
        ©f.m.m All Rights Reserved.
      </div>
    </div>
  );
}

export {Footer};