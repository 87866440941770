import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const SuccessToast = (context) => {
    toast.success(context, {
        position: toast.POSITION.BOTTOM_RIGHT
    })
}

export const ErrorToast = (context) => {
    toast.error(context, {
        position: toast.POSITION.BOTTOM_RIGHT
    })
}