import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ExpandMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "./htuTop.css";

function HowToUse() {

  const navigation = useNavigate("/test");

  return (
    <div className="sphowToUse">
        <div className="sphtuHeadLine">オファー作成から撮影までの流れ</div>
        <div className="sphtu">
            <div className="spstep">
                <SearchIcon fontSize="large"/><div className="spstepHeadLine">モデルを選ぶ</div>
                <div className="spcc">モデル一覧から条件に合うモデルを選びます。</div>
            </div>

            <ChevronRightIcon fontSize="large"/>

            <div className="spstep">
                <AssignmentIcon fontSize="large"/><div className="spstepHeadLine">撮影依頼を送信</div>
                <div className="spcc">選んだモデルに直接オファーすることができます。</div>
            </div>

            <ChevronRightIcon fontSize="large"/>

            <div className="spstep">
                <ForwardToInboxIcon fontSize="large"/><div className="spstepHeadLine">メッセージで相談</div>
                <div className="spcc">撮影日時や撮影場所、衣装などを決定します。</div>
            </div>

            <ChevronRightIcon fontSize="large"/>

            <div className="spstep">
                <CameraAltIcon fontSize="large"/><div className="spstepHeadLine">撮影当日</div>
                <div className="spcc">モデルへのギャラは当日現金でお渡し下さい。</div>
            </div>
        </div>
    </div>
  );
}

export {HowToUse};
