import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTop } from "../../control/header/header";
import { Filtered } from "../../control/filter/filSearchModel";
import { Footer } from "../../control/footer/footer";
import { GetAllModel, GetModelByMailaddress } from "../../../util/web_api";
import { PAGE_KIND } from "../../../util/define";
import "./srSearchModel.css";
import { loading, watchingUserInfo } from "../../../recoil/atom";
import { useRecoilState } from "recoil";

function SP_SearchModel() {

    const [models, setModels] = useState([]);
    const [watchingUser, setWatchingUser] = useRecoilState(watchingUserInfo);

    const navigation = useNavigate("");

    const handleOpenPage = async () => {
        GetAllModel((res) => {
            setModels(res.data);
            console.log(res.data);
        }, (e) => {
            console.warn(e);
        })
    }

    const handleClickModelItem = (mailaddress, password) => {
        GetModelByMailaddress(mailaddress, (res) => {
            setWatchingUser({ userKind: res.data.userKind, mailaddress: res.data.mailaddress });
            navigation("/search/user/detail", { state: { resdata: res.data, pageKind: PAGE_KIND.PROFILE } });
        }, (e) => {
            console.warn(e.response.data);
        })
    }

    useEffect(() => {
        handleOpenPage();
    }, [])

    return (
        <div>
            <HeaderTop />
            <Filtered />
            <div className="spsr">
                <div className="spsrAria">
                    <div className="spsrHeadLine">モデル一覧</div>
                    <div style={{ backgroundColor: "white", padding: "2vw" }}>
                        {models.map((model) => {
                            return (
                                <div
                                    className="spModelItem"
                                    onClick={() => handleClickModelItem(model.mailaddress)}
                                    style={{ display: "inline-block", padding: "3vw", marginBottom: "1vh", cursor: "pointer" }}>
                                    <img src={model.profilePhoto} alt="画像表示に失敗しました" style={{ width: "22vw", height: "22vw" }} />
                                    <div>{model.firstName} {model.secondName}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export { SP_SearchModel };
