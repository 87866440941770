import React from "react";
import { Button } from "@mui/material"
import Picture from "./../../../../Images/pic.png";
import { useNavigate } from "react-router-dom";
import "./oTop.css";

function OverView() {

  const navigation = useNavigate("/test");

  return (
    <div className="overView">
      <div className="aboutUsTitle">
        <div className="aboutUst">このサイトについて</div>
        <img className="aboutUsPic" src={Picture} height="300px" />
      </div >
      <div className="aboutUsOv">
        <div className="oHeadLine">福岡の美容師とモデルをつなぐWEBサービス</div>
        <div className="oComplement">個人集客をして売上を上げたい美容師さん<br/>福岡でサロンモデル、モデルとして輝きたい女性、男性<br/>
        f.m.mはそんな方々が今よりもっと活躍出来る場所として誕生したサイトです。</div>
        <Button variant="outlined" color="inherit" sx={"color:black;"} style={{width: "200px", fontSize: "medium", marginTop: 10, marginLeft: 0}}
                      onClick={() => navigation("/HairStylist_Regist")}>美容師 新規登録</Button>
        <Button variant="outlined" color="inherit" sx={"color:black;"} style={{width: "200px", fontSize: "medium", marginTop: 10, marginLeft: 20}}
                onClick={() => navigation("/Model_Regist")}>モデル 応募</Button>
        <Button variant="outlined" color="inherit" sx={"color:black;"} style={{width: "200px", fontSize: "medium", marginTop: 10,  marginLeft: 20}}
                onClick={() => navigation("/Company_Regist")}>企業の方はこちら</Button>
      </div>
    </div>
  );
}

export {OverView};
