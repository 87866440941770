import React, { useEffect, useState } from "react";
import "./chat.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  ConversationHeader,
  Avatar,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
  ExpansionPanel,
  TypingIndicator,
  MessageSeparator
} from "@chatscope/chat-ui-kit-react";
import { GetChatRoomByChatRoomId, GetMessageByChatRoomId, SendMessage } from "../../../util/web_api";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { USER_KIND } from "../../../util/define";

function Chat(props) {

  const token = localStorage.getItem("token");
  const clientData = JSON.parse(token);

  const [chatContent, setChatContent] = useState([]);
  const [context, setContext] = useState("");
  const [senderId, setSenderId] = useState("");
  const [chatRoom, setChatRoom] = useState();

  const handleOnSend = () => {
    const poster = {
      ChatRoomId: props.chatRoomId,
      SenderId: senderId,
      MessageContext: context
    }
    console.log(poster);
    SendMessage(poster, (res) => {
      console.log(res.data);
      handleGetMessages();
    }, (e) => {
      console.log(e);
    })
  }

  const handleGetChatRoom = () => {
    GetChatRoomByChatRoomId(props.chatRoomId, (res) => {
      console.log(res.data);
      if (senderId == "" && clientData.userKind == USER_KIND.MODEL) setSenderId(res.data.modelId);
      if (senderId == "" && clientData.userKind == USER_KIND.HAIRDRESSER) setSenderId(res.data.clientId);
      setChatRoom(res.data);
    }, (e) => {
      console.log(e);
    })
  }

  const handleGetMessages = () => {
    GetMessageByChatRoomId(props.chatRoomId, (res) => {
      console.log(res.data);
      setChatContent(res.data);
    }, (e) => {
      console.log(e);
    })
  }

  // なんでこれじゃだめ？？？
  const displayMessages = () => {
    console.log("メッセージ読み込み");
    chatContent.map((content) => {
      return (
        <Message
          model={{
            message: content.messageContext,
            sentTime: content.sendTime,
            sender: content.senderId,
            position: "normal",
            direction: content.senderId == senderId ? "outgoing" : "incoming"
          }}
        />
      )
    })
  }

  useEffect(() => {
    return handleGetChatRoom();
  }, []);

  useEffect(() => {
    return handleGetMessages();
  }, [senderId]);

  return (
    <div className="pcMainMenuChat">
      <MainContainer>
        <ChatContainer>
          <ConversationHeader>
            <Avatar
              src={chatRoom != null ? clientData.userKind == 0 ? chatRoom.hairdresserProfilePhoto : chatRoom.modelProfilePhoto : "読み込み中"}
              name={chatRoom != null ? clientData.userKind == 0 ? chatRoom.hairdresserName : chatRoom.modelName : "読み込み中"}
            />
            <ConversationHeader.Content
              userName={chatRoom != null ? clientData.userKind == 0 ? chatRoom.hairdresserName : chatRoom.modelName : "読み込み中"}
              info="Active 10 mins ago"
            />
            <ConversationHeader.Actions>
              <InfoButton />
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList>
            {chatContent.map((content) => {
              return (
                <Message
                  model={{
                    message: content.messageContext,
                    sentTime: content.sendTime,
                    sender: content.senderId,
                    position: "normal",
                    direction: content.senderId == senderId ? "outgoing" : "incoming"
                  }}
                />
              )
            })}
          </MessageList>
          <MessageInput placeholder="メッセージを入力してください" onSend={() => handleOnSend()} onChange={(val) => setContext(val)} />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}

export { Chat };
