import React from "react";
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import PersonIcon from '@mui/icons-material/Person';
import "./header.css";
import { AutoLogin } from "../../../util/web_api";
import { USER_KIND } from "../../../util/define";
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from "../../../util/util";


function HeaderTop() {

  const navigation = useNavigate();

  const handleClickLogin = () => {
    if (localStorage.getItem("token") != null) {
      const token = JSON.parse(localStorage.getItem("token"));
      let user = "";
      switch (token.userKind) {
        case USER_KIND.MODEL:
          user = "Model";
          break;
        case USER_KIND.HAIRDRESSER:
          user = "Hairdresser";
          break;
        case USER_KIND.COMPANY:
          user = "Company";
          break;
        default:
          break;
      }
      AutoLogin(user, token.mailaddress, token.password, (res) => {
        navigation(`/${user.toLowerCase()}`, { state: { resdata: res.data, pageKind: 0 } })
        SuccessToast("自動ログインに成功しました");
      }, () => {
        navigation("/account/login");
        ErrorToast("自動ログインに失敗しました")
      });
    } else {
      navigation("/account/login");
    }
  }
  return (
    <div className="header">
      <div className="hTitle"><span onClick={() => navigation("/")} style={{ cursor: "pointer" }}>f.m.m</span></div>
      <div className="hOperationMenu">
        <Tooltip title="モデルを探す"><Button color="inherit" onClick={() => navigation("/search/user",)}><SearchSharpIcon fontSize="large" /></Button></Tooltip>
        <Tooltip title="アカウント関連"><Button color="inherit" onClick={handleClickLogin}><PersonIcon fontSize="large" /></Button></Tooltip>
      </div>
    </div>
  );
}

export { HeaderTop };
