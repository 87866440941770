import React from "react";
import Rank1 from "../../../Images/Rank1.jpg";
import Rank2 from "../../../Images/Rank2.jpg";
import Rank3 from "../../../Images/Rank3.jpg";
import Rank4 from "../../../Images/Rank4.jpg";
import Rank5 from "../../../Images/Rank5.jpg";

function DisplayRank(props) {
  return (
    <div style={{marginTop:"20px", paddingLeft:"15vw"}}>
        <div style={{fontSize:"x-large"}}>f.m.mモデルランキング</div>
        <div style={{display:"inline-block", width:"12vw", height:"12.5vw"}}>
            <img src={Rank1} style={{width:"12vw", height:"12vw"}}/>
            <div>Rank1. NANAKO</div>
        </div>
        <div style={{display:"inline-block", marginLeft:"2.5vw", width:"12vw", height:"12.5vw"}}>
            <img src={Rank2} style={{width:"12vw", height:"12vw"}}/>
            <div>Rank2. MAIKO</div>
        </div>
        <div style={{display:"inline-block", marginLeft:"2.5vw", width:"12vw", height:"12.5vw"}}>
            <img src={Rank3} style={{width:"12vw", height:"12vw"}}/>
            <div>Rank3. KANA</div>
        </div>
        <div style={{display:"inline-block", marginLeft:"2.5vw", width:"12vw", height:"12.5vw"}}>
            <img src={Rank4} style={{width:"12vw", height:"12vw"}}/>
            <div>Rank4. AMANE</div>
        </div>
        <div style={{display:"inline-block", marginLeft:"2.5vw", width:"12vw", height:"12.5vw"}}>
            <img src={Rank5} style={{width:"12vw", height:"12vw"}}/>
            <div>Rank5. MIKU</div>
        </div>
    </div>
  );
}

export {DisplayRank};
