import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTop } from "../../control/header/header";
import { Filtered } from "../../control/filter/filSearchModel";
import { Footer } from "../../control/footer/footer";
import { GetAllModel, GetAllSalone, GetModelByMailaddress } from "../../../util/web_api";
import { PAGE_KIND } from "../../../util/define";
import "./SaloneList.css";
import { loading, watchingUserInfo } from "../../../recoil/atom";
import { useRecoilState } from "recoil";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ExpandMore } from "@mui/icons-material";
import image from "../../../Images/image5.jpg";
import { Accordion, AccordionDetails, AccordionSummary, Link } from "@mui/material";

const salones = [
  { stylistName: 'S', saloneName: 'Shared Salone 福岡大名店', access: '地下鉄天神駅から徒歩5分', detail: '表参道の技術を福岡で提供し、様々なスタイルやジャンルを高クオリティで表現できるよう努めます。' },
  { stylistName: 'H', saloneName: 'hair resort Ai 亀戸店', access: '亀戸水神駅12分', detail: 'hair resort Ai 亀戸店　伊丹智希です！メンズ、レディース両方担当させて頂きます！' },
  { stylistName: 'P', saloneName: 'PRIMAL千葉店', access: '千葉駅5分 / 千葉中央駅5分', detail: 'リラックスできる空間を提供し、技術は120%を提供するよう心がけています。髪についての悩みぜひ相談してください。全力で解決していきます' },
]

function SaloneList(props) {

  const [saloneData, setSaloneData] = useState(null);

  useEffect(() => {
    GetAllSalone((res) => {
      console.log(res.data);
      setSaloneData(res.data);
    }, (e) => {
      console.log(e.response.data);
    })
  }, [])

  return (
    <div className="pcMainMenuMyPage">
      <div className="pcViewModelInstruction">
        <Accordion style={{ marginBottom: 50 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>条件で検索する</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              条件項目が入ります
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div>
          <div className="srHeadLine">あなたに合った美容室・美容院</div>
          {saloneData !== null && saloneData.map((salone) => {
            return (
              <Card className="saloneCard" style={{ display: 'inline-block', margin: 10, verticalAlign: 'top' }} sx={{ maxWidth: '31%' }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      {salone.stylistName}
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                  }
                  title={salone.saloneName}
                  subheader={salone.access}
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={image}
                  alt="Paella dish"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {salone.detail}
                  </Typography>
                </CardContent>
              </Card>
            )
          })}
        </div>
        <div style={{ marginTop: 30 }}>
          <div className="srHeadLine">新着の美容室・美容院</div>
          {salones.map((salone) => {
            return (
              <Card className="saloneCard" style={{ display: 'inline-block', margin: 10, verticalAlign: 'top' }} sx={{ maxWidth: '31%' }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      {salone.stylistName}
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                  }
                  title={salone.saloneName}
                  subheader={salone.access}
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={image}
                  alt="Paella dish"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {salone.detail}
                  </Typography>
                </CardContent>
              </Card>
            )
          })}
        </div>
        <div style={{ marginTop: 30 }}>
          <div className="srHeadLine">カットが評判の美容室・美容院</div>
          {salones.map((salone) => {
            return (
              <Card className="saloneCard" style={{ display: 'inline-block', margin: 10, verticalAlign: 'top' }} sx={{ maxWidth: '31%' }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      {salone.stylistName}
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                  }
                  title={salone.saloneName}
                  subheader={salone.access}
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={image}
                  alt="Paella dish"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {salone.detail}
                  </Typography>
                </CardContent>
              </Card>
            )
          })}<br />
          <Link>カットが評判の美容室・美容院をすべて見る</Link>
        </div>
      </div>
    </div>
  );
}

export { SaloneList };
