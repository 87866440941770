import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import { ModelLogin } from "./ModelLogin/mlLogin";
import { CompanyLogin } from "./CompanyLogin/clLogin";
import { Biginer } from "./biginers/bLogin";
import "./fLogin.css";

function Form() {

    const [value, setValue] = useState(0);
    const navigation = useNavigate("/test");

    const handleChange = (e, v) => {
        setValue(v);
    }

    const changeActivity = (id) => {
        setValue(id);
    }

    return (
        <div className="fLogin">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs textColor="inherit" TabIndicatorProps={{style:{backgroundColor:"black"}}} value={value} onChange={handleChange} centered>
                        <Tab style={{width:"20vw"}} label="美容師・法人ログイン"/>
                        <Tab style={{width:"20vw"}} label="モデルログイン"/>
                        <Tab style={{width:"20vw"}} label="初めての方はこちら"/>
                    </Tabs>
                </Box>
                <TabPanel value={0}>
                    <CompanyLogin/>
                </TabPanel>
                <TabPanel value={1}>
                    <ModelLogin/>
                </TabPanel>
                <TabPanel value={2}>
                    <Biginer/>
                </TabPanel>
            </TabContext>
        </div>
    );
}

export {Form};
