import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderTop } from "../../control/header/header";
import { Footer } from "../../control/footer/footer";
import "./mRegist.css";
import { Button, MenuItem, TextField } from "@mui/material";
import { RegistModel } from "../../../util/web_api";

function Model_Regist(children) {

  const navigation = useNavigate("");
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [kFirstName, setKFirstName] = useState("");
  const [kSecondName, setKSecondName] = useState("");
  const [mailaddress, setMailaddress] = useState("");
  const [birthYMD, setBirthYMD] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sex, setSex] = useState(false);
  const [workID, setWorkID] = useState(0);
  const [howFound, setHowFound] = useState("");
  const [shootExperience, setShootExperience] = useState("");
  const [selfPR, setSelfPR] = useState("");
  const [sNS1, setSNS1] = useState("");
  const [sNS2, setSNS2] = useState("");
  const [sNS3, setSNS3] = useState("");
  const [image, setImage] = useState("");

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  }

  const handleChangeSecondName = (e) => {
    setSecondName(e.target.value);
  }

  const handleChangeKFirstName = (e) => {
    setKFirstName(e.target.value);
  }

  const handleChangeKSecondName = (e) => {
    setKSecondName(e.target.value);
  }

  const handleChangeMailaddress = (e) => {
    setMailaddress(e.target.value);
  }

  const handleChangeBirthYMD = (e) => {
    setBirthYMD(e.target.value);
  }

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  }

  const handleChangeSex = (e) => {
    setSex(e.target.value);
  }

  const handleChangeWorkID = (e) => {
    setWorkID(e.target.value);
  }

  const handleChangeHowFound = (e) => {
    setHowFound(e.target.value);
  }

  const handleChangeShootExperience = (e) => {
    setShootExperience(e.target.value);
  }

  const handleChangeSelfPR = (e) => {
    setSelfPR(e.target.value);
  }

  const handleChangeSNS1 = (e) => {
    setSNS1(e.target.value);
  }

  const handleChangeSNS2 = (e) => {
    setSNS2(e.target.value);
  }

  const handleChangeSNS3 = (e) => {
    setSNS3(e.target.value);
  }

  const handleChoiceImage = (e) => {
    const files = e.target.files
    console.log(files);
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader()
      reader.onload = (e) => {
        setImage(e.target.result)
      };
      reader.readAsDataURL(file)
    } else {
      console.log("0以下");
      setImage("")
    }
  }

  const handlePostNewModel = () => {

    const Poster = {
      FirstName: firstName,
      KFirstName: kFirstName,
      SecondName: secondName,
      KSecondName: kSecondName,
      Mailaddress: mailaddress,
      PhoneNumber: phoneNumber,
      BirthYMD: birthYMD,
      Sex: sex,
      WorkID: workID,
      HowFound: howFound,
      ShootExperience: shootExperience,
      SelfPR: selfPR,
      SNS1: sNS1,
      SNS2: sNS2,
      SNS3: sNS3,
      ProfilePhoto: image,
    }

    console.log(Poster);
    RegistModel(Poster, (res) => {
      console.log(res.data);
      navigation("/")
    }, (e) => {
      console.warn(e);
    })
  }

  return (
    <div>
      <HeaderTop />
      <div className="mRegistTitle">モデル応募フォーム</div>
      <div className="mRegistDetail">
        モデル登録には一次審査(web応募)が必須となります。<br />以下のフォームにて必要事項のご記入をお願い致します。<br />
        尚、審査にはお時間をいただく場合がございます。<br />一次審査通過者のみ二次審査の詳細ご案内を送らせていただきます。
      </div>
      <div className="RegistForm">
        <div className="FormTitle">応募フォーム<span className="m_attention">*は必須項目です</span></div>
        <div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeFirstName} style={{ width: "15vw" }} label="姓" variant="filled" required />
            <TextField onChange={handleChangeSecondName} style={{ width: "15vw", marginLeft: "2vw" }} label="名" variant="filled" required />
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeKFirstName} style={{ width: "15vw" }} label="セイ" variant="filled" required />
            <TextField onChange={handleChangeKSecondName} style={{ width: "15vw", marginLeft: "2vw" }} label="メイ" variant="filled" required />
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeMailaddress} style={{ width: "32vw" }} label="メールアドレス" variant="filled" required />
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangePhoneNumber} style={{ width: "15vw" }} label="電話番号" variant="filled" required />
            <TextField onChange={handleChangeBirthYMD} style={{ width: "10vw", marginLeft: "2vw" }} label="生年月日" variant="filled" required />
            <TextField onChange={handleChangeSex} style={{ width: "8vw", marginLeft: "2vw" }} label="性別" variant="filled" required select>
              <MenuItem value={false}>男</MenuItem>
              <MenuItem value={true}>女</MenuItem>
            </TextField>
            <TextField onChange={handleChangeWorkID} style={{ width: "8vw", marginLeft: "2vw" }} label="職業" variant="filled" required select>
              <MenuItem value={1}>学生</MenuItem>
              <MenuItem value={2}>会社員</MenuItem>
            </TextField>
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeHowFound} style={{ width: "32vw" }} label="募集を知った経緯" variant="filled" multiline rows={3} />
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeShootExperience} style={{ width: "32vw" }} label="その他撮影経験" variant="filled" multiline rows={3} />
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeSelfPR} style={{ width: "32vw" }} label="自己PR" variant="filled" multiline required rows={8} />
          </div>
          <div className="m_inputInfo">
            <TextField onChange={handleChangeSNS1} style={{ width: "15vw" }} label="SNSアカウント1" variant="filled" required />
            <TextField onChange={handleChangeSNS2} style={{ width: "15vw", marginLeft: "2vw" }} label="SNSアカウント2" variant="filled" />
            <TextField onChange={handleChangeSNS3} style={{ width: "15vw", marginLeft: "2vw" }} label="SNSアカウント3" variant="filled" />
            <div>Instagram、Twitter、TikTok 最低1つはご記入下さい</div>
          </div>
          <div className="m_inputInfo">
            <input type="file" accept="image/*" onChange={(e) => handleChoiceImage(e)} />
            <div>アプリ加工、プリクラではないものを添付してください。</div>
          </div>
          <Button onClick={handlePostNewModel} variant="contained" size="large">応募する(まだ応募は完了しません)</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export { Model_Regist };
