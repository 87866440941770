import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderTop } from "../../control/header/header";
import { Footer } from "../../control/footer/footer";
import "./Inquiry.css";
import { Button, Checkbox, FormControlLabel, FormGroup, Link, TextField } from "@mui/material";

function Inquiry() {

  const navigation = useNavigate("");
  const location = useLocation();

  return (
    <div>
        <HeaderTop/>
        <div className="mRegistTitle">お問い合わせ</div>
        <div className="mRegistDetail">
            お問い合わせいただく前に質問したい内容がよくある質問ページにないかをご確認ください。<br/>
            f.m.mモデルへのご応募はモデル応募ページからお願いいたします。<br/>
            画像を添付したい等の理由で、メールによる連絡を希望される方はinfo@coupe-tokyo.comまでご連絡ください。
        </div>
        <div className="RegistForm">
          <div className="FormTitle">お問い合わせフォーム<span className="m_attention">*は必須項目です</span></div>
          <div>
            <div className="m_inputInfo">
              <TextField style={{width:"60vw"}} label="名前" variant="filled" required/>
            </div>
            <div className="m_inputInfo">
              <TextField style={{width:"60vw"}} label="返信用メールアドレス" variant="filled" required/>
            </div>
            <div className="m_inputInfo">
              <TextField style={{width:"60vw"}} label="お問い合わせ内容" variant="filled" multiline rows={10} required/>
            </div>
            <Link underline="hover" target="_blank" style={{cursor:"pointer"}} href="/#/termsService" rel="noopener">利用規約</Link>
            <FormGroup className="c_inputInfo" style={{width:"170px"}}>
                <FormControlLabel control={<Checkbox/>} label="利用規約に同意"/>
            </FormGroup>
            <Button variant="contained" size="large">送信する</Button>
          </div>
        </div>
        <Footer/>
    </div>
  );
}

export {Inquiry};
